import axios from "axios";

const KEYCLOAK_SERVER_PORT = 8022;

const PERSON_SERVICE_PORT = 8080;
const CONTRACT_SERVICE_PORT = 8081;
const PRODUCT_SERVICE_PORT = 8082;
const OFFER_SERVICE_PORT = 8083;
const PRICING_SERVICE_PORT = 8084;
const QUOTE_SERVICE_PORT = 8085;
const AUTH_SERVICE_PORT = 8086;

const ORCHESTRATOR_SERVICE_PORT = 8888;

const axiosInstance = axios.create();

const config = {
  axios: axiosInstance,

  vehicule_product_id: "079bc80e-8e60-40ea-8f03-b4f5da4f8489",

  KEYCLOAK_BASE_URL: `http://localhost:${KEYCLOAK_SERVER_PORT}`,

  PERSON_BASE_URL: `http://localhost:${PERSON_SERVICE_PORT}`,

  CONTRACT_QUERIES_BASE_URL: `http://localhost:${CONTRACT_SERVICE_PORT}/queries`,
  CONTRACT_COMMANDS_BASE_URL: `http://localhost:${CONTRACT_SERVICE_PORT}/commands`,
  CONTRACT_RESSOURCES_BASE_URL: `http://localhost:${CONTRACT_SERVICE_PORT}/resources`,

  PRODUCT_QUERIES_BASE_URL: `http://localhost:${PRODUCT_SERVICE_PORT}/queries`,
  PRODUCT_COMMANDS_BASE_URL: `http://localhost:${PRODUCT_SERVICE_PORT}/commands`,
  PRODUCT_RESSOURCES_BASE_URL: `http://localhost:${PRODUCT_SERVICE_PORT}/resources`,

  OFFER_QUERIES_BASE_URL: `http://localhost:${OFFER_SERVICE_PORT}/queries`,
  OFFER_COMMANDS_BASE_URL: `http://localhost:${OFFER_SERVICE_PORT}/commands`,
  OFFER_RESSOURCES_BASE_URL: `http://localhost:${OFFER_SERVICE_PORT}/resources`,

  PRICING_COMMANDS_BASE_URL: `http://localhost:${PRICING_SERVICE_PORT}/commands`,
  PRICING_QUERIES_BASE_URL: `http://localhost:${PRICING_SERVICE_PORT}/queries`,

  QUOTE_COMMANDS_BASE_URL: `http://localhost:${QUOTE_SERVICE_PORT}/commands`,
  QUOTE_QUERIES_BASE_URL: `http://localhost:${QUOTE_SERVICE_PORT}/queries`,

  AUTH_BASE_URL: `http://localhost:${AUTH_SERVICE_PORT}`,

  ORCHESTRATOR_BASE_URL: `http://localhost:${ORCHESTRATOR_SERVICE_PORT}`
};

export default config;
