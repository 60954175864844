import React, { useEffect, useState } from "react";
import { Input, Button } from "iosis-storybook/dist/bundle";
import { ReactComponent as Icon } from "../../img/icons/six-dots.svg";
import { CgMoreVerticalAlt } from "react-icons/cg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const SelectItems = ({ items, setItems }) => {
  const [visibleRowId, setVisibleRowId] = useState(null);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(reorderedItems);
  };

  const handleInputChange = (id, newValue) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, value: newValue } : item
      )
    );
  };
  const toggleWindow = (id) => {
    setVisibleRowId((prev) => (prev === id ? null : id));
  };
  const handleAdd = (index) => {
    const newId =
      items.length > 0 ? Math.max(...items.map((item) => item.id)) + 1 : 1;
    const newItem = { id: newId, value: `` };
    const newItems = [...items];
    newItems.splice(index + 1, 0, newItem);
    setItems(newItems);
    setVisibleRowId(null);
  };

  const handleDelete = (id) => {
    setItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".select-items-button-container")) {
        setVisibleRowId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='items'>
        {(provider) => (
          <div ref={provider.innerRef} {...provider.droppableProps}>
            {items.map((item, index) => (
              <Draggable
                key={String(item.id)}
                draggableId={String(item.id)}
                index={index}
              >
                {(provider) => (
                  <div
                    className='select-items-container'
                    ref={provider.innerRef}
                    {...provider.draggableProps}
                  >
                    <span
                      className='prorety-drag'
                      {...provider.dragHandleProps}
                    >
                      <Icon />
                    </span>
                    <div className='select-items-input'>
                      <Input
                        label='item'
                        placeholder='Saisir'
                        type='text'
                        onChange={(e) => handleInputChange(item.id, e)}
                      />
                    </div>
                    <span className='select-items-button-container'>
                      <Button
                        styled='icon'
                        icon={<CgMoreVerticalAlt />}
                        size='xl'
                        onClick={(e) => {
                          toggleWindow(item.id);
                        }}
                      />
                      {visibleRowId === item.id && (
                        <div className='select-items-window-container'>
                          <span
                            className='text-sm-regular color-neutral-800'
                            style={{
                              cursor: "pointer"
                            }}
                            onClick={() => handleAdd(index)}
                          >
                            Ajouter
                          </span>
                          {index >= 1 && (
                            <span
                              className='text-sm-regular color-neutral-800'
                              style={{
                                cursor: "pointer"
                              }}
                              onClick={() => handleDelete(item.id)}
                            >
                              Supprimer
                            </span>
                          )}
                        </div>
                      )}
                    </span>
                  </div>
                )}
              </Draggable>
            ))}
            {provider.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SelectItems;
