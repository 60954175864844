import React, { useEffect, useState } from "react";
import {
    Header,
    Table,
    Snackbar,
    SidePanel,
    Button,
    Status
  } from "iosis-storybook/dist/bundle";
import { GoStar } from "react-icons/go";
import { IoMdAdd } from "react-icons/io";
import UpdateUser from "./UpdateUser";
import AddUser from "./addUser";

const Users = () => {
    const [openSidePanelAddUser, setOpenedSidePanelAddUser] = useState(false);
    const [openSidePanelEditUser, setOpenedSidePanelEditUser] = useState(false);
    const [users, setUsers] = useState([
        {
            id:0,
            profile:"https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg",
            fullName:"Rhouma Landolsi",
            firstName: "Rhouma",
            lastName:"Landolsi",
            email:"pechtri@gmail.com",
            role:"admin",
            createdAt:"12 December 2024",
            status:'PENDING',
            password:"changeme"
        },
        {
            id:1,
            profile:"https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg",
            fullName:"Roronoa Zoro",
            firstName: "Roronoa",
            lastName:"Zoro",
            email:"zoro@gmail.com",
            role:"user",
            createdAt:"12 December 2024",
            status:'ACTIVE',
            password:"changeme"
        },
        {
            id:2,
            profile:"https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg",
            fullName:"Aizen Sosuke",
            firstName: "Aizen",
            lastName:"Sosuke",
            email:"aizen@gmail.com",
            role:"user",
            createdAt:"12 December 2024",
            status:'DISABLED',
            password:"changeme"
        }
    ]);
    const [user, setUser] = useState({})
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState("");
    const [key, setKey] = useState("");
    const [selectedUser, setSelectedUser] = useState({});
    const [copySelectedUser, setCopySelectedUser] = useState({});
    const [showSnackBar, setShowSnackBar] = useState(false);
    const [textSnackBar, setTextSnackBar] = useState("");
    const [isBlocked, setIsBlocked] = useState(false);

    
    const StatusList = {
        ACTIVE: { type: "complete", title: "Actif" },
        DISABLED: { type: "danger", title: "Suspendu" },
        PENDING: { type: "warning", title: "En attente" },
    };

    const userRoles = {
        user:{ role: "user", title: "Utilisateur" },
        admin:{ role: "admin", title: "Super utilisateur" },
    }

    const tableColumns = [
      { label: "Profil", key: "profile", 
        getter: (value) => (
        <div className="user-profile-image">
          <img src={value} alt="profile" className="user-profile-image"/>
          
        </div>) 
      },
      { label: "Nom de l’utilisateur", key: "fullName" },
      { label: "E-mail", key: "email" },
      { label: "Rôle", key: "role",
          getter: (value) => (
              userRoles[value].title
          )
      },
      {
        label: "date de création",
        key: "createdAt",
      },
      {
          key: "status",
          label: "Statut",
          getter: (value) => (
              <Status
                  type={StatusList[value].type}
                  title={StatusList[value].title}
                  forTable
              />
          )
      },
      {
            key: "status",
            label: "Action",
            getter: (status) => {
              switch (status) {
                case "DISABLED":
                  return (
                    <Button
                      styled='tertiary'
                      label='Réintégrer'
                      onClick={(e) => e.stopPropagation()}
                      size={"sm"}
                    />
                  );
                case "ACTIVE":
                  return (
                    <Button
                      styled='tertiary'
                      label='Suspendre'
                      onClick={(e) => e.stopPropagation()}
                      size={"sm"}
                    />
                  );
                case "PENDING":
                  return (
                    <Button
                      styled='tertiary'
                      label='Relancer'
                      onClick={(e) => e.stopPropagation()}
                      size={"sm"}
                    />
                  );
                default:
                  return <></>;
              }
            }
          }
    ];

    const onClickMain = () => {
        setOpenedSidePanelAddUser(true);
    };

    const handleAddUser = async () => {
        setOpenedSidePanelAddUser(false);
        setTextSnackBar("Utilisateur ajouté avec succès");
        setShowSnackBar(true);
        setUsers([{
            id:users.length+1,
            profile:"https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg",
            fullName:`${user.firstName} ${user.lastName}`,
            firstName: user.firstName,
            lastName:user.lastName,
            email:user.email,
            role:user.role,
            createdAt:"12 December 2024",
            status:'PENDING'
        }, ...users]);
    };

    const handleEditUser = (user) => {
        if (openSidePanelAddUser) {
          setOpenedSidePanelAddUser(false);
        }
        setOpenedSidePanelEditUser(true);
        // getPerson(person);
        setCopySelectedUser(user)
        setSelectedUser(user);
    };

    const handleUpdateUser = async (user) => {
          const copyUsers = users.map(obj => {
            if (obj.id === user.id) {
              return user // Create a new object with updated properties
            }
            return obj;
          });
        setUsers(copyUsers)
        setTextSnackBar("Une personne a été modifiée avec succès!");
        setShowSnackBar(true);
          
    };

    const handleCloseEditSidePanel = () =>{
      setOpenedSidePanelEditUser(false)
      setSelectedUser(null)
      setCopySelectedUser({})
    }
    return (
        <div className='users-container'>
            {openSidePanelAddUser && (
                <SidePanel
                    title='Ajouter un utilisateur'
                    onClose={() => setOpenedSidePanelAddUser(false)}
                    content={<AddUser 
                      user={user} setUser={setUser}
                    />}
                    primaryButton={
                        <Button
                        styled='primary'
                        label='Ajouter'
                        onClick={handleAddUser}
                        startIcon={<IoMdAdd size={22} />}
                        blocked={isBlocked}
                        loading={false}
                        size={"md"}
                        />
                    }
                    secondaryButton={
                        <Button
                        styled='tertiary'
                        label='Annuler'
                        onClick={() => setOpenedSidePanelAddUser(false)}
                        size={"md"}
                        />
                    }
                />
            )}
        
            {openSidePanelEditUser && (
                <SidePanel
                title={selectedUser.fullName}
                category='Utilisateur'
                onClose={() => handleCloseEditSidePanel()}
                content={
                    <UpdateUser 
                        selectedUser={selectedUser} 
                        setSelectedUser={setSelectedUser} 
                        setCopySelectedUser={setCopySelectedUser} 
                        copySelectedUser={copySelectedUser} 
                        handleUpdateUser={handleUpdateUser} 
                        StatusList={StatusList}
                        userRoles={userRoles}
                    />
                } 
                />
            )}
            {showSnackBar && (
                <Snackbar
                type={"success"}
                title={textSnackBar}
                size='small'
                autoClose={8000}
                closeCallBack={() => {
                    setShowSnackBar(false);
                }}
                />
            )}
            <Header
                title={"Gestion des utilisateurs"}
                subtitle={
                "Consultez ici la liste des personnes et créez des relations entre elles"
                }
                titleIcon={<GoStar color='#62AF98' />}
                primaryButton={
                users.length > 0 && (
                    <Button
                    styled='primary'
                    label='Ajouter un utilisateur'
                    onClick={onClickMain}
                    startIcon={<IoMdAdd size={22}/>}
                    size={"md"}
                    />
                )
                }
            />
            <div className='content-wrapper'>
                <Table
                  onRowClick={(user) => {
                      handleEditUser(user);
                  }}
                  withSelect={true}
                  pageSize={pageSize}
                  setPageSize={(size) => {
                      setPageSize(size);
                  }}
                  currentPage={page}
                  setCurrentPage={(page) => {
                      setPage(page);
                  }}
                  setSort={setSort}
                  entriesCount={users.length}
                  emptyPageTextButton='Ajouter un utilisateur'
                  columns={tableColumns}
                  data={users}
                  emptyPageTitle='Il n’y a aucun utilisateur sur la liste'
                  emptyPageDesc='Cliquez sur “Ajouter un utilisateur” pour commencer'
                  pageSizes={[10, 20, 50]}
                  onSearch={()=>{}}
                  selectedRowId={selectedUser?.id}
                />
            </div>
          </div>
    );
};

export default Users;
