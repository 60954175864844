import Personnes from "../../pages/personne/Personnes";
import Properties from "../../pages/properties/Properties";
import Relations from "../../pages/relations/Relations";
import Roles from "../../pages/roles/Roles";
import Contracts from "../../pages/contrat/Contracts";
import ContractDetails from "../../pages/contrat/ContractDetails";
import Companies from "../../pages/companies/Companies";
import Products from "../../pages/products/Products";
import Offers from "../../pages/offers/Offers";
import OfferDetails from "../../pages/offers/OfferDetails";
import QuoteAutoList from "../../pages/quotes/automobile/QuoteAutoList";
import { FiLink } from "react-icons/fi";
import { BsPersonFill } from "react-icons/bs";
import { FaList } from "react-icons/fa6";
import { ReactComponent as ContactsLogo } from "../../img/contacts.svg";
import { ReactComponent as ContractsLogo } from "../../img/contrats.svg";
import { ReactComponent as ProductsLogo } from "../../img/products.svg";
import { ReactComponent as OffersLogo } from "../../img/offers.svg";
import { ReactComponent as devisLogo } from "../../img/devis.svg";
import { ReactComponent as usersIcon } from "../../img/users.svg";
import { ReactComponent as favorisIcon } from "../../img/favoris.svg";
import Users from "../../pages/users/Users";

export const topOptions = [
  {
    id: 1,
    label: "Contacts",
    icon: ContactsLogo,
    childs: [
      {
        id: "personnes",
        label: "Personnes",
        path: "/persons",
        component: Personnes,
        detailsPath: "/persons/:id"
        // detailsComponent: PersonDetails
      },
      {
        id: "entreprises",
        label: "Entreprises",
        path: "/companies",
        component: Companies,
        detailsPath: "/companies/:id"
        // detailsComponent: CompanyDetails
      }
    ]
  },
  {
    id: 2,
    label: "Contrats",
    icon: ContractsLogo,
    path: "/contracts",
    component: Contracts,
    detailsPath: "/contracts/:id",
    detailsComponent: ContractDetails
  },
  {
    id: 3,
    label: "Produits",
    path: "/products",
    icon: ProductsLogo,
    component: Products,
    detailsPath: "/products/:id"
  },

  {
    id: 4,
    label: "Offres",
    path: "/offers",
    component: Offers,
    icon: OffersLogo,
    detailsPath: "/offers/:id",
    detailsComponent: OfferDetails
  },
  {
    id: 5,
    label: "Devis",
    icon: devisLogo,
    childs: [
      {
        id: "Automobile",
        label: "Automobile",
        path: "/quote_automobile",
        component: QuoteAutoList,
        detailsPath: "/quote_automobile",
        detailsComponent: QuoteAutoList
      }
    ]
  },
  { line: true },
  {
    id: 6,
    label: "Utilisateurs",
    path: "/users",
    component: Users,
    icon: usersIcon
  },
  {
    id: 7,
    label: "Favoris",
    path: "/favoris",
    component: Users,
    icon: favorisIcon
  }
];

export const Settings = [
  {
    title: "Propriétés",
    path: "/properties",
    subtitle: "Description line lorem ipsum dolor sit lorem ipsum dolor sit",
    iconColor: "#9736E8",
    icon: FaList,
    component: Properties
  },
  {
    title: "Relations",
    subtitle: "Description line lorem ipsum dolor sit lorem ipsum dolor sit",
    icon: FiLink,
    iconColor: "#4945FF",
    path: "/relations",
    component: Relations
  },
  {
    title: "Rôles",
    subtitle: "Description line lorem ipsum dolor sit lorem ipsum dolor sit",
    icon: BsPersonFill,
    iconColor: "#328048",
    path: "/roles",
    component: Roles
  }
];
