import React, { useState } from "react";
import {
    Button,
    InputWithActions,
    Status,
    Accordeon,
    Select,
    Input
  } from "iosis-storybook/dist/bundle";
import { IoMdAdd } from "react-icons/io";
import { IoCheckmarkOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { MdModeEditOutline } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";
import { generatePassword } from "../../utils/functions";

const UpdateUser = ({selectedUser, setSelectedUser, setCopySelectedUser, copySelectedUser, handleUpdateUser, StatusList, userRoles}) => {
    const [generatedPassword, setGeneratedPassword] = useState("");
    const [isUpdatingName, setIsUpdatingName] = useState(false);
    const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);

    const handleChangePassword = () =>{
        setIsUpdatingPassword(true)
        setGeneratedPassword("")
    }
    
    const handleGeneratePassword = () =>{
        setGeneratedPassword(generatePassword(16))
    }

    const handleUpdatePassword = () =>{
        console.log(generatedPassword);
        
        setCopySelectedUser({...copySelectedUser, password:generatedPassword})
        handleUpdateUser({...copySelectedUser, password:generatedPassword})
        setIsUpdatingPassword(false)
    }

    const accordionInfos = () =>
        <>
            <div className="mt-2">
                <span className='text-xs-bold color-neutral-700'>Créé le</span>
                <div className='text-sm-regular color-neutral-600'>
                    {selectedUser.createdAt}
                </div>
            </div>

            <div className="mt-2">
                <span className='text-xs-bold color-neutral-700'>Ajouté par</span>
                <div className='text-sm-regular color-neutral-600'>
                    Aizen Sosuke
                </div>
            </div>

            <div className=' mt-2'>
                <span className='text-xs-bold color-neutral-700'>
                    E-mail:{" "}
                </span>
                <div className='mr-2'>
                    <InputWithActions
                    value={copySelectedUser.email}
                    button={<MdModeEditOutline size={16} />}
                    mainEditingButton={<IoCheckmarkOutline size={10} />}
                    onClickMain={() => {
                        setCopySelectedUser(copySelectedUser);
                        handleUpdateUser(copySelectedUser)
                    }}
                    secondEditingButton={<IoCloseOutline size={10} />}
                    onClickSecond={() => {
                        setCopySelectedUser(selectedUser);
                    }}
                    onChange={(value) => {
                        setCopySelectedUser({ ...copySelectedUser, email: value });
                    }}
                    />
                </div>
            </div>

            <div className='mt-2'>
                <span className='text-xs-bold color-neutral-700'>
                    Rôle{" "}
                </span>
                <div className=''>
                    <InputWithActions
                    value={userRoles[copySelectedUser.role].title}
                    button={<MdModeEditOutline size={16} />}
                    mainEditingButton={<IoCheckmarkOutline size={10} />}
                    onClickMain={() => {
                        setCopySelectedUser(copySelectedUser);
                        handleUpdateUser(copySelectedUser)
                    }}
                    secondEditingButton={<IoCloseOutline size={10} />}
                    onClickSecond={() => {
                        setCopySelectedUser(selectedUser);
                    }}
                    onChange={(value) => {
                        setCopySelectedUser({ ...copySelectedUser, role: value });
                    }}
                    selectItems={[
                        {
                            id:0,
                            value:"user",
                            label:"Utilisateur"
                        },
                        {
                            id:1,
                            value:"admin",
                            label:"Super utilisateur"
                        },
                    ]}
                    />
                </div>
            </div>
        </>
    
    const accordionPassword = () =>
        <>
            <div className="roww justify-space-between align-flex-end">
                <div className='content-input coll-10'>
                    { isUpdatingPassword ? 
                        <Input
                            placeholder={"Saisir"}
                            label={"Changer le mot de passe"}
                            type='password'
                            description=''
                            disabled={false}
                            errorMessage=''
                            required={false}
                            value={generatedPassword}
                            onChange={(password) => {
                                setGeneratedPassword(password)
                            }}
                        />
                        :
                        <Input
                            placeholder={"Saisir"}
                            label={"Mot de passe"}
                            type='password'
                            description=''
                            disabled={false}
                            errorMessage=''
                            required={false}
                            value={copySelectedUser.password}
                            // onChange={(password) => {
                            //     setSelectedUser({ ...selectedUser, password: generatedPassword});
                            // }}
                        />
                    }
                </div>
                <div className="mb-3">
                    { 
                        isUpdatingPassword ? 
                        <Button
                            styled='tertiary'
                            label={'Générer'}
                            onClick={() => {handleGeneratePassword()}}
                            size={"lg"}
                        />
                        :
                        <Button
                            styled='tertiary'
                            label={'Changer'}
                            onClick={() => {handleChangePassword()}}
                            size={"lg"}
                        />
                    }
                </div>
            </div>
            { isUpdatingPassword && 
                <div className="mt-5 password-buttons">
                    <Button
                        styled='primary'
                        label='Mettre à jour'
                        onClick={()=>{handleUpdatePassword()}}
                        startIcon={<IoMdAdd size={22} />}
                        loading={false}
                        size={"md"}
                    />
                    <div className="ml-4">
                        <Button
                            styled='tertiary'
                            label='Annuler'
                            onClick={() => {setIsUpdatingPassword(false)}}
                            size={"md"}
                        />
                    </div>                  
                </div>
            }
        </>

    const handleClickStatusButton = () =>{
        if(copySelectedUser.status === "ACTIVE"){
            setSelectedUser({...copySelectedUser, status: "DISABLED"})
            setCopySelectedUser({...copySelectedUser, status: "DISABLED"})
            handleUpdateUser({...copySelectedUser, status: "DISABLED"})
        }
        if(copySelectedUser.status === "DISABLED"){
            setSelectedUser({...copySelectedUser, status: "ACTIVE"})
            setCopySelectedUser({...copySelectedUser, status: "ACTIVE"})
            handleUpdateUser({...copySelectedUser, status: "ACTIVE"})
        }
    }
    
    const actionButtons = (status) =>{
        switch (status) {
            case "DISABLED":
            return (
                <Button
                    styled='primary'
                    label='Réintégrer'
                    onClick={() => {handleClickStatusButton()}}
                    size={'sm'}
                />
            );
            case "ACTIVE":
            return (
                <Button
                    styled='primary'
                    label='Suspendre'
                    onClick={() => {handleClickStatusButton()}}
                    size={'sm'}
                />
            );
            case "PENDING":
            return (
                <Button
                    styled='primary'
                    label='Relancer'
                    onClick={(e) => e.stopPropagation()}
                    size={'sm'}
                />
            );
            default:
            return <></>;
        }
    }

    return (
        <div className='user-update'>
            
            <div className="roww justify-space-between">
                <div className='user-status coll-9'>
                    <div className='user-name'>
                    {isUpdatingName ||
                    (selectedUser.hasOwnProperty("fullName") &&
                    selectedUser.name !== "") ? (
                        <InputWithActions
                        value={copySelectedUser.fullName}
                        button={<MdModeEditOutline />}
                        mainEditingButton={<IoCheckmarkOutline size={10} />}
                        onClickMain={() => {
                            setSelectedUser(
                            JSON.parse(JSON.stringify(copySelectedUser))
                            );
                            handleUpdateUser(copySelectedUser);
                            setIsUpdatingName(false);
                        }}
                        secondEditingButton={<IoCloseOutline size={10} />}
                        onClickSecond={() => {
                            setCopySelectedUser(
                            JSON.parse(JSON.stringify(selectedUser))
                            );
                            setIsUpdatingName(false);
                        }}
                        onClickEdit={(status) => {
                            setIsUpdatingName(status);
                        }}
                        onChange={(value) => {
                            setCopySelectedUser({
                            ...copySelectedUser,
                            name: value
                            });
                        }}
                        typing={!selectedUser.hasOwnProperty("fullName") && true}
                        // size={"medium"}
                        />
                    ) : (
                        <div>
                        <Button
                            styled='tertiary'
                            label='Ajouter'
                            onClick={() => {
                                setIsUpdatingName(true);
                            }}
                            endIcon={<IoMdAdd />}
                        />
                        </div>
                    )}
                    </div>
        
                    {!isUpdatingName && (
                    <div id='status-tags' className={`status-tag`}>
                        <Status
                        type={StatusList[copySelectedUser.status].type}
                        title={StatusList[copySelectedUser.status].title}
                        forTable
                        />
                    </div>
                    )}
                </div>
                <div className="">
                    {actionButtons(copySelectedUser.status)}
                </div>
            </div>
            <div className='divider' />
            <div>
                <Accordeon
                    title={"Informations"}
                    children={accordionInfos()}
                    isChild={true}
                />
            </div>
            <div className='divider' />
            <div>
                <Accordeon
                    title={"Mot de passe"}
                    children={accordionPassword()}
                    isChild={true}
                />
            </div>
        </div>
    );
};

export default UpdateUser;
