import React, { useState, useEffect, useMemo } from "react";
import {
  Input,
  Checkbox,
  Select,
  SwitchButton
} from "iosis-storybook/dist/bundle";

import SelectItems from "./SelectItems";
const AddPropertyContent = ({ mode, property, setProperty }) => {
  const [selectedEntities, setSelectedEntities] = useState(
    property?.entity || []
  );
  const [selectedType, setSelectedType] = useState();
  const [items, setItems] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" }
  ]);

  const entites = [
    {
      id: 1,
      label: "Personnes"
    },
    {
      id: 2,
      label: "Entreprises"
    },
    {
      id: 3,
      label: "Contrats"
    }
  ];
  const dataList = [
    { id: 1, value: "String", label: "Chaîne de caractère" },
    { id: 2, value: "Number", label: "Numéro" },
    { id: 3, value: "email", label: "E-mail" },
    { id: 4, value: "phone_number", label: "Numéro de téléphone" },
    { id: 5, value: "boolean", label: "Booléan" },
    { id: 6, value: "Date", label: "Date" },
    { id: 7, value: "Uni-Select", label: "Sélection unique" },
    { id: 8, value: "Multi-Select", label: "Sélection multiple" }
  ];
  const selectedPropertyType = useMemo(() => {
    if (mode !== "add")
      return dataList.find((item) => item.id === property.idProperty);
  }, []);
  useEffect(() => {
    if (mode === "add") {
      setProperty({});
      setSelectedEntities([]);
    }
  }, [mode]);

  useEffect(() => {
    if (selectedType === "Number") {
      setProperty((prev) => ({
        ...prev,
        validationRules: {
          isNegative: false
        }
      }));
    }
    if (selectedType === "Date") {
      setProperty((prev) => ({
        ...prev,
        validationRules: {
          isBefore: false,
          isAfter: false
        }
      }));
    }
    if (selectedType === "Uni-Select" || selectedType === "Multi-Select") {
      setProperty((prev) => ({
        ...prev,
        validationRules: {
          items: items
        }
      }));
    }
  }, [selectedType]);

  const handleMultipleSelect = (item) => {
    setSelectedEntities((prevSelectedEntities) => {
      const isSelected = prevSelectedEntities.some(
        (selectedItem) => selectedItem.id === item.id
      );
      if (isSelected) {
        setProperty((prev) => ({
          ...prev,
          entites: prevSelectedEntities.filter(
            (selectedItem) => selectedItem.id !== item.id
          )
        }));

        return prevSelectedEntities.filter(
          (selectedItem) => selectedItem.id !== item.id
        );
      } else {
        setProperty((prev) => ({
          ...prev,
          entites: [...prevSelectedEntities, item]
        }));

        return [...prevSelectedEntities, item];
      }
    });
  };

  const renderAdditionalInputs = () => {
    switch (selectedType) {
      case "String":
        return (
          <div className='roww align-center justify-space-between'>
            <Input
              label='Nombre de caractère'
              placeholder='Minimum'
              type='number'
              onChange={(n) =>
                setProperty((prev) => ({
                  ...prev,
                  validationRules: {
                    ...prev.validationRules,
                    minLength: n
                  }
                }))
              }
            />
            <span className='property-sep'>-</span>
            <Input
              placeholder='Maximum'
              type='number'
              onChange={(n) =>
                setProperty((prev) => ({
                  ...prev,
                  validationRules: {
                    ...prev.validationRules,
                    maxLength: n
                  }
                }))
              }
            />
          </div>
        );
      case "Number":
        return (
          <div className='property-form-container'>
            <div className='roww align-center justify-space-between'>
              <Input
                label='Nombre de chiffre'
                placeholder='Minimum'
                type='number'
                onChange={(n) =>
                  setProperty((prev) => ({
                    ...prev,

                    validationRules: {
                      ...prev.validationRules,
                      minLength: n
                    }
                  }))
                }
              />
              <span className='property-sep'>-</span>
              <Input
                placeholder='Maximum'
                type='number'
                onChange={(n) =>
                  setProperty((prev) => ({
                    ...prev,
                    validationRules: {
                      ...prev.validationRules,
                      maxLength: n
                    }
                  }))
                }
              />
            </div>
            <Input
              label='Nombre de chiffre'
              placeholder='Sélectionner'
              type='number'
              onChange={(n) =>
                setProperty((prev) => ({
                  ...prev,
                  validationRules: {
                    ...prev.validationRules,
                    decimalLength: n
                  }
                }))
              }
            />
            <div className='roww align-center justify-space-between'>
              <span className='text-xs-bold color-neutral-700'>
                Autoriser les nombres négatifs
              </span>
              <SwitchButton
                simple
                onSwitch={() => {
                  setProperty((prev) => ({
                    ...prev,
                    validationRules: {
                      ...prev.validationRules,
                      isNegative: !prev.validationRules.isNegative
                    }
                  }));
                }}
              />
            </div>
          </div>
        );
      case "Date":
        return (
          <div className='property-form-container'>
            <div className='roww align-center justify-space-between'>
              <span className='text-xs-bold color-neutral-700'>
                Date inférieure à aujourd’hui
              </span>
              <SwitchButton
                simple
                onSwitch={() => {
                  setProperty((prev) => ({
                    ...prev,
                    validationRules: {
                      ...prev.validationRules,
                      isBefore: !prev.validationRules.isBefore
                    }
                  }));
                }}
              />
            </div>
            <div className='roww align-center justify-space-between'>
              <span className='text-xs-bold color-neutral-700'>
                Date supérieure à aujourd’hui
              </span>
              <SwitchButton
                simple
                onSwitch={() => {
                  setProperty((prev) => ({
                    ...prev,
                    validationRules: {
                      ...prev.validationRules,
                      isAfter: !prev.validationRules.isAfter
                    }
                  }));
                }}
              />
            </div>
          </div>
        );
      case "Uni-Select":
        return <SelectItems items={items} setItems={setItems} />;
      case "Multi-Select":
        return (
          <>
            <SelectItems items={items} setItems={setItems} />
            <div className='roww align-center justify-space-between'>
              <Input
                label='Nombre de choix'
                placeholder='Minimum'
                type='number'
                onChange={(n) =>
                  setProperty((prev) => ({
                    ...prev,

                    validationRules: {
                      ...prev.validationRules,
                      minChoice: n
                    }
                  }))
                }
              />
              <span className='property-sep'>-</span>
              <Input
                placeholder='Maximum'
                type='number'
                onChange={(n) =>
                  setProperty((prev) => ({
                    ...prev,
                    validationRules: {
                      ...prev.validationRules,
                      maxChoice: n
                    }
                  }))
                }
              />
            </div>
          </>
        );
    }
  };

  return (
    <div className='property-form-container'>
      <Input
        label='Label'
        placeholder='Saisir'
        type='text'
        onChange={(label) => {
          setProperty((prev) => ({
            ...prev,
            label: label
          }));
        }}
        value={property?.label || ""}
      />
      <Input
        label='Code'
        placeholder='Saisir'
        type='text'
        onChange={(code) => {
          setProperty((prev) => ({
            ...prev,
            code: code
          }));
        }}
        value={property?.code || ""}
      />
      <div className='columnn gapp-1'>
        <span className='text-xs-bold color-neutral-700'>
          Ajouter cette propriété dans
        </span>
        <div className='roww align-center gapp-2'>
          {entites.map((entity, index) => (
            <Checkbox
              label={entity.label}
              onChange={() => handleMultipleSelect(entity)}
              checked={selectedEntities.some((el) => el === entity.label)}
            />
          ))}
        </div>
      </div>
      <Select
        label='Type de propriété'
        placeholder='Sélectionner'
        dataList={dataList}
        onSelectItem={(el) => {
          setSelectedType(el.value);
          setProperty((prev) => ({
            ...prev,
            validationRules: {},
            type: el.value
          }));
        }}
        selectedValue={selectedPropertyType}
      />
      {renderAdditionalInputs()}
    </div>
  );
};

export default AddPropertyContent;
