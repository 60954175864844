import React, { useEffect, useState } from "react";
// import "./companies.scss";
import {
  Header,
  Table,
  Snackbar,
  SidePanel,
  Input,
  Button,
  Accordeon,
  InputWithActions
} from "iosis-storybook/dist/bundle";
import { IoMdMail } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import { GoStar } from "react-icons/go";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import { MdModeEditOutline } from "react-icons/md";

import { ReactComponent as Link } from "../../img/icons/link.svg";
import Relations from "../personne/Relations";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  getAllCompanies,
  createCompany,
  getCompanyDetails,
  updateCompany
} from "./services.js";
import useFetchData from "../../customHooks/useFetchData";
import useAddElement from "../../customHooks/useAddElement";
import useEditElement from "../../customHooks/useEditElement.js";
import { formatDate } from "../../utils/utilities";

const Companies = () => {
  const [openSidePanelAddEntreprise, setOpenedSidePanelAddEntreprise] =
    useState(false);
  const [openSidePanelEditEntreprise, setOpenedSidePanelEditentreprise] =
    useState(false);
  const [entreprise, setEntreprise] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("");
  const [key, setKey] = useState("");
  const [selectedEntreprise, setSelectedEntreprise] = useState({});
  const [copySelectedEntreprise, setCopySelectedEntreprise] = useState({});
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [textSnackBar, setTextSnackBar] = useState("");
  const [validateForm, setValidateForm] = useState(false);
  const [mailCopied, setMailCopied] = useState(false);
  const [relations, setRelations] = useState({});

  const { dataList, entriesCount, isLoading, error, refetch } = useFetchData(
    getAllCompanies,
    page,
    pageSize,
    sort
  );
  const { isSubmitting, submitError, handleSubmit } = useAddElement(
    entreprise,
    createCompany,
    refetch
  );
  const { isEditing, editError, handleEdit } = useEditElement(
    { id: copySelectedEntreprise.id, [key]: copySelectedEntreprise[key] },
    updateCompany,
    getCompanyDetails,
    refetch
  );
  const showCopiedMsg = () => {
    setMailCopied(true);
    setTimeout(() => setMailCopied(false), 1000);
  };
  const tableColumns = [
    { label: "Immatricule fiscale", key: "taxId" },
    { label: "Nom", key: "name" },
    { label: "email", key: "email" },
    {
      label: "Créé le",
      key: "creationDate",
      getter: (date) => formatDate(date)
    }
  ];
  useEffect(() => {
    if (entreprise.name !== "" || entreprise.email !== "") {
      setValidateForm(true);
    } else {
      setValidateForm(false);
    }
  }, [entreprise]);

  useEffect(() => {
    setCopySelectedEntreprise(selectedEntreprise);
  }, [selectedEntreprise]);

  const onClickMain = () => {
    setOpenedSidePanelAddEntreprise(true);
  };

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(selectedEntreprise.email);
    showCopiedMsg();
  };

  const getCompany = async (company) => {
    try {
      const response = await getCompanyDetails(company.id);
      console.log(response.data);
      if (response.data && response.data.personData) {
        setSelectedEntreprise(response.data.personData);
        setRelations(response.data?.relations);
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (e) {
      console.error("Error in getCompany:", e);
      throw e;
    }
  };

  const sideBarAddentrepriseContent = () => (
    <>
      <div className='content-input'>
        <Input
          placeholder={"Saisir"}
          label={"Immatricule fiscal"}
          type='text'
          description=''
          disabled={false}
          errorMessage=''
          required={false}
          onChange={(taxId) => {
            setEntreprise({ ...entreprise, taxId: taxId });
          }}
        />
      </div>
      <div className='content-input'>
        <Input
          placeholder={"Saisir"}
          label={"Nom de l'entreprise"}
          type='text'
          description=''
          disabled={false}
          errorMessage=''
          required={false}
          onChange={(name) => {
            setEntreprise({ ...entreprise, name: name });
          }}
        />
      </div>
      <div className='content-input'>
        <Input
          placeholder={"Saisir"}
          label={"Adresse e-mail"}
          type='text'
          description=''
          disabled={false}
          errorMessage=''
          required={false}
          iconLeft={<IoMdMail />}
          onChange={(email) => {
            setEntreprise({ ...entreprise, email: email });
          }}
        />
      </div>
    </>
  );

  useEffect(() => {
    sideBarDisplayEditentrepriseContent();
  }, [selectedEntreprise]);

  const handleEditEntreprise = (entreprise) => {
    if (openSidePanelEditEntreprise) {
      setOpenedSidePanelEditentreprise(false);
    }
    setOpenedSidePanelEditentreprise(true);
    getCompany(entreprise);
    setCopySelectedEntreprise(entreprise);
    setSelectedEntreprise(entreprise);
  };

  const handleAddEntreprise = async () => {
    await handleSubmit();
    if (submitError) {
      setOpenedSidePanelAddEntreprise(false);
      setTextSnackBar("Erreur lors de l'ajout!");
      setShowSnackBar(true);
    } else {
      setOpenedSidePanelAddEntreprise(false);
      setTextSnackBar("Une entreprise a été ajoutée avec succès!");
      setShowSnackBar(true);
    }
    setEntreprise({});
    setValidateForm(false);
  };

  const handleUpdateEntreprise = async (entreprise) => {
    try {
      handleEdit();
      setTextSnackBar("Une entreprise a été modifiée avec succès!");
      setShowSnackBar(true);
    } catch (e) {
      setTextSnackBar("Erreur lors de la modification!");
      setShowSnackBar(true);
    }
  };

  const accordionInfos = () => (
    <div className='columnn gapp-2'>
      <div className='columnn'>
        <span className='text-xs-bold color-neutral-700'>Créé le</span>
        <div className='text-sm-regular color-neutral-600'>
          {/* {selectedEntreprise.createdAt} */}
          {formatDate(selectedEntreprise.creationDate)}
        </div>
      </div>
      <div className='columnn'>
        {selectedEntreprise.hasOwnProperty("phoneNumber") ? (
          <InputWithActions
            value={copySelectedEntreprise.phoneNumber}
            label='Numéro de téléphone'
            button={<MdModeEditOutline />}
            mainEditingButton={<IoCheckmarkOutline size={10} />}
            onClickMain={() => {
              setSelectedEntreprise(copySelectedEntreprise);
            }}
            secondEditingButton={<IoCloseOutline size={10} />}
            onClickSecond={() => {
              setCopySelectedEntreprise(selectedEntreprise);
            }}
            onChange={(value) => {
              setCopySelectedEntreprise({
                ...copySelectedEntreprise,
                phoneNumber: value
              });
            }}
            typing={!selectedEntreprise.hasOwnProperty("phoneNumber") && true}
          />
        ) : (
          <div className='columnn'>
            <div className='text-xs-bold color-neutral-700'>
              Numéro de téléphone
            </div>
            <div>
              <Button
                styled='tertiary'
                label='Ajouter'
                onClick={() => {}}
                endIcon={<IoMdAdd/>}
                size={"sm"}
              />
            </div>
            
          </div>
        )}
      </div>
      <div className="columnn">
        {selectedEntreprise.hasOwnProperty("address") ? (
          <>
            <InputWithActions
              value={copySelectedEntreprise.address}
              label='Adresse'
              button={<MdModeEditOutline />}
              mainEditingButton={<IoCheckmarkOutline size={10} />}
              onClickMain={() => {
                setSelectedEntreprise(copySelectedEntreprise);
              }}
              secondEditingButton={<IoCloseOutline size={10} />}
              onClickSecond={() => {
                setCopySelectedEntreprise(selectedEntreprise);
              }}
              onChange={(value) => {
                setCopySelectedEntreprise({
                  ...copySelectedEntreprise,
                  address: value
                });
              }}
              typing={!selectedEntreprise.hasOwnProperty("address") && true}
            />
          </>
        ) : (
          <div>
            <div className='text-xs-bold color-neutral-700'>Adresse</div>
            <div>
              <Button
                styled='tertiary'
                label='Ajouter'
                onClick={() => {}}
                endIcon={<IoMdAdd />}
                size={"sm"}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const sideBarDisplayEditentrepriseContent = () => (
    <>
      <div className='wrapper'>
        <div>
          <span className='text-sm-semibold color-neutral-600'>
            Réference:{" "}
          </span>
          <span className='text-sm-regular color-neutral-1000'>
            {selectedEntreprise?.id?.slice(0, 8)}
          </span>
        </div>
        <div>
          <span>
            <InputWithActions
              value={copySelectedEntreprise.name}
              button={<MdModeEditOutline size={16} />}
              mainEditingButton={<IoCheckmarkOutline size={10} />}
              onClickMain={() => {
                handleUpdateEntreprise(copySelectedEntreprise);
              }}
              secondEditingButton={<IoCloseOutline size={10} />}
              onClickSecond={() => {
                setKey("");
                setCopySelectedEntreprise(selectedEntreprise);
              }}
              onChange={(value) => {
                setKey("name");
                setCopySelectedEntreprise({
                  ...copySelectedEntreprise,
                  name: value
                });
              }}
              size={"medium"}
            />
          </span>
        </div>
        <div className='email'>
          <span className='text-sm-semibold color-neutral-600 label'>
            E-mail:
          </span>
          <span className='mr-2'>
            <InputWithActions
              value={copySelectedEntreprise.email}
              button={<MdModeEditOutline size={16} />}
              mainEditingButton={<IoCheckmarkOutline size={10} />}
              onClickMain={() => {
                handleUpdateEntreprise(copySelectedEntreprise);
              }}
              secondEditingButton={<IoCloseOutline size={10} />}
              onClickSecond={() => {
                setKey("");
                setCopySelectedEntreprise(selectedEntreprise);
              }}
              onChange={(value) => {
                setKey("email");
                setCopySelectedEntreprise({
                  ...copySelectedEntreprise,
                  email: value
                });
              }}
            />
          </span>
          <Button
            styled={"icon"}
            size={"sm"}
            icon={<Link />}
            onClick={copyToClipBoard}
          />
          {mailCopied && (
            <span className='text-xs-regular color-primary-700 mt-1 ml-1'>
              Lien copié !
            </span>
          )}
        </div>
      </div>
      <div className='divider' />
      <div>
        <Accordeon
          title='Informations'
          children={accordionInfos()}
          action={
            <Button
              size={"sm"}
              styled='icon'
              icon={<BsThreeDotsVertical size={10} />}
            />
          }
          withDesc={true}
          withAction={false}
          isChild={true}
          onAction={() => {}}
          label='Action'
          withdots={true}
        />
        <div className='divider' />
        <Relations
          selectedElement={selectedEntreprise}
          setSelectedElement={setSelectedEntreprise}
          relations={relations}
          refreshData={() => getCompany(selectedEntreprise)}
        />
      </div>
    </>
  );

  const handleCloseEditSidePanel = () =>{
    setOpenedSidePanelEditentreprise(false)
    setCopySelectedEntreprise({})
  }

  return (
    <div className='entreprise-container'>
      {openSidePanelAddEntreprise && (
        <div className='sidepanel'>
          <SidePanel
            title='Ajouter une entreprise'
            onClose={() => setOpenedSidePanelAddEntreprise(false)}
            content={sideBarAddentrepriseContent()}
            primaryButton={
              <Button
                styled='primary'
                label='Ajouter'
                onClick={handleAddEntreprise}
                startIcon={<IoMdAdd size={22} />}
                blocked={!validateForm}
                loading={isSubmitting}
                size={"md"}
              />
            }
            secondaryButton={
              <Button
                styled='tertiary'
                label='Annuler'
                onClick={() => setOpenedSidePanelAddEntreprise(false)}
                size={"md"}
              />
            }
          />
        </div>
      )}

      {openSidePanelEditEntreprise && (
        <div className='sidepanel'>
          <SidePanel
            title={selectedEntreprise.id?.slice(0, 8)}
            category='Entreprises'
            onClose={() => handleCloseEditSidePanel()}
            content={sideBarDisplayEditentrepriseContent()}
            /*  primaryButton={
              <Button
                styled='primary'
                label='Ajouter'
                onClick={handleAddEntreprise}
                endIcon={<IoMdAdd size={22} />}
                blocked={!validateForm}
                size={"md"}
              />
            }
            secondaryButton={
              <Button
                styled='tertiary'
                label='Annuler'
                onClick={() => setOpenedSidePanelAddEntreprise(false)}
                size={"md"}
              />
            } */
            // onClickPrimary={handleUpdateEntreprise}
          />
        </div>
      )}
      {showSnackBar && (
        <Snackbar
          type='success'
          title={textSnackBar}
          size='small'
          autoClose={5000}
          closeCallBack={(state) => {
            setShowSnackBar(state);
          }}
        />
      )}
      <Header
        title={"Entreprises"}
        subtitle={
          "Consultez ici la liste des entreprises et créez des relations entre elles"
        }
        titleIcon={<GoStar />}
        primaryButton={
          dataList.length > 0 && (
            <Button
              styled='primary'
              label='Ajouter une entreprise'
              onClick={onClickMain}
              size={"md"}
              startIcon={<IoMdAdd size={22}/>}
            />
          )
        }
      />
      <div className='container'>
        <Table
          isLoading={isLoading}
          onRowClick={(entreprise) => {
            handleEditEntreprise(entreprise);
          }}
          withSelect={false}
          pageSize={pageSize}
          setPageSize={(size) => {
            setPageSize(size);
          }}
          currentPage={page}
          setCurrentPage={(page) => {
            setPage(page);
          }}
          setSort={setSort}
          entriesCount={entriesCount}
          emptyPageTextButton='Ajouter une entreprise'
          columns={tableColumns}
          data={dataList}
          emptyPageTitle='Il n’y a aucune entreprise ajoutée dans la plateforme'
          emptyPageDesc='Cliquez sur “Ajouter une entreprise” pour commencer'
          onClickEmptyPage={onClickMain}
          pageSizes={[10, 20, 50]}
          selectedRowId={copySelectedEntreprise?.id}
        />
      </div>
    </div>
  );
};

export default Companies;
