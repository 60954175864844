import { useState } from "react";
import {
  Input,
  DatePicker,
  Select,
  Checkbox,
  SwitchButton,
  Tabs
} from "iosis-storybook/dist/bundle";
const AddOfferContent = ({activeTab, setActiveTab}) => {
  const tabs = ["Informations générales", "Configuration", "Tarification"];
  return (
    <div className="columnn">
      <div className="tabs">
        {tabs.map((tab, index) => (
          <span
            className={`tab text-xs-bold color-neutral-500 ${
              activeTab === index ? "current" : ""
            }`}
            onClick={() => {
              setActiveTab(index);
            }}
          >
            {tab}
          </span>
        ))}
      </div>
      {activeTab === 0 && <GenerealInfo />}
      {activeTab === 1 && <Configuration />}
      {activeTab === 2 && <Tarification />}
    </div>
  );
};

export default AddOfferContent;

const GenerealInfo = () => {
  return (
    <div className="columnn gapp-3">
      <Input label="Nom de l'offre" placeholder="Saisir" />
      <DatePicker label="Date de début" years={[2023, 2024]} onSelect={(e)=>{console.log(e);}} />
      <DatePicker label="Date de fin" years={[2023, 2024]} onSelect={(e)=>{console.log(e);}}/>
      <Select
        label="Entreprise"
        placeholder="Sélectionner l'entreprise"
        dataList={[
          { id: 1, label: "Comar", value: "comar" },
          { id: 2, label: "SlashUp ", value: "slashup" },
        ]}
        onSelectItem={() => {}}
      />
      <Select
        label="Produit"
        placeholder="Sélectionner le produit"
        dataList={[
          { id: 1, label: "Assurance véhicule", value: "idProduct1" },
          { id: 2, label: "Assurance immobiler", value: "idProduct2" },
        ]}
        onSelectItem={() => {}}
      />
    </div>
  );
};

const Configuration = () => {
  const [selectedTab, setSelectedTab] = useState("Durée");
  return (
    <div className="columnn gapp-2">
      <div className="roww">
        <span className="text-sm-semibold color-neutral-600">
          Offre:&nbsp;
        </span>
        <span className="text-sm-regular color-neutral-1000">
          Basic
        </span>
      </div>
      <div className="roww">
        <span className="text-sm-semibold color-neutral-600">
          Configuration du produit:&nbsp;
        </span>
        <span className="text-sm-regular color-neutral-1000">
          Assurance véhicule
        </span>
      </div>
      <div className="roww justify-space-between align-flex-end">
        <div className="min-max"><Input label="Puissance" placeholder="Minimum" /></div>
        <span className="mb-3">-</span>
        <div className="min-max"><Input placeholder="Maximum" /></div>
      </div>
      <Select
        label="Type de véhicule"
        placeholder="Saisir"
        dataList={[
          { id: 1, label: "PickUp", value: "pickup" },
          { id: 2, label: "Berline ", value: "berline" },
        ]}
        onSelectItem={() => {}}
      />
      <Select
        label="Garanties prises en charge"
        placeholder="Sélectionner le produit"
        dataList={[
          { id: 1, label: "Pare à brise", value: "parebrise" },
          { id: 2, label: "Vitres", value: "vitre" },
        ]}
        onSelectItem={() => {}}
      />
      <div>
        <span className="text-xs-bold color-neutral-700 mb-4">
          Date de mise en circulation
        </span>
        <Tabs
          onSwitch={(e) => {setSelectedTab(e)}}
          items={["Durée", "Intervalle", "Date fix"]}
        />
        { 
          selectedTab === "Durée" &&
          <div className="roww justify-space-between align-flex-end">
          <div className="coll-7">
            <Input label="" placeholder="Saisir" onChange={(e)=>{console.log(e);}}/>
          </div>
          <span className="mb-3 ">-</span>
          <div className="coll-3">
            <Select
              placeholder="Choisir"
              dataList={[
                { id: 0, label: "Ans", value: "Ans" },
                { id: 1, label: "Mois", value: "Mois" },
                { id: 2, label: "Jours", value: "Jours" },
              ]}
              onSelectItem={() => {}}
            />
          </div>
          </div>
        }
        { 
          selectedTab === "Intervalle" &&
          <div className="roww justify-space-between align-flex-end">
            <div className="min-max">
              <DatePicker years={[2023, 2024]} onSelect={(e)=>{console.log(e);}}/>
            </div>
            <span className="mb-3">-</span>
            <div className="min-max">
              <DatePicker years={[2023, 2024]} onSelect={(e)=>{console.log(e);}}/>
            </div>
            
          </div>
        }
        { 
          selectedTab === "Date fix" &&
          <div className="mt-4">
            <DatePicker years={[2023, 2024]} onSelect={(e)=>{console.log(e);}}/>
          </div>
        }
      </div>
      
      <div className="roww justify-space-between">
        <span className="text-xs-bold color-neutral-700">
          Assistance technique 24/7
        </span>
        <SwitchButton simple onSwitch={() => {}} />
      </div>
    </div>
  );
};

const Tarification = () => {
  const [pricingData, setPricingData] = useState({});

  const handlePrices = (key) => {
    if (key in pricingData) {
      const Prices = { ...pricingData };
      delete Prices[key];
      setPricingData(Prices);
    } else {
      setPricingData({
        ...pricingData,
        [key]: { price: "", currency: "EUR" },
      });
    }
  };

  const handlePricingData = (key, value) => {
    setPricingData({
      ...pricingData,
      [key]: { ...pricingData[key], price: value },
    });
  };

  return (
    <div className="columnn gapp-3">
      <div className="roww align-center">
        <span className="coll-1">
          <Checkbox
            onChange={() => {
              handlePrices("ONESHOT");
            }}
            checked={"ONESHOT" in pricingData}
          />
        </span>
        <span className="coll-11">
          <Input
            label="Paiement comptant"
            placeholder="Saisir"
            type="number"
            value={pricingData?.ONESHOT?.price}
            onChange={(value) => {
              handlePricingData("ONESHOT", value);
            }}
            disabled={!!!pricingData.ONESHOT}
            rightText={"€"}
          />
        </span>
      </div>
      <div className="roww align-center">
        <span className="coll-1">
          <Checkbox
            onChange={() => {
              handlePrices("MONTH");
            }}
            checked={"MONTH" in pricingData}
          />
        </span>
        <span className="coll-11">
          <Input
            label="Paiement mensuel"
            placeholder="Saisir"
            type="number"
            value={pricingData?.MONTH?.price}
            onChange={(value) => {
              handlePricingData("MONTH", value);
            }}
            disabled={!!!pricingData.MONTH}
            rightText={"€/Mois"}
          />
        </span>
      </div>
      <div className="roww align-center">
        <span className="coll-1">
          <Checkbox
            onChange={() => {
              handlePrices("YEAR");
            }}
            checked={"YEAR" in pricingData}
          />
        </span>
        <span className="coll-11">
          <Input
            label="Paiement annuel"
            placeholder="Saisir"
            type="number"
            value={pricingData?.YEAR?.price}
            onChange={(value) => {
              handlePricingData("YEAR", value);
            }}
            disabled={!!!pricingData.YEAR}
            rightText={"€/An"}
          />
        </span>
      </div>
    </div>
  );
};
