import { config } from "../../config/config";

const getAllCompanies = (page = 1, size = 10, sort = "") => {
  return config.axios.get(config.PERSON_BASE_URL + "/legal-persons", {
    params: { page, size, sort }
  });
};
const getCompanyDetails = (id) => {
  return config.axios.get(config.PERSON_BASE_URL + "/legal-persons/" + id);
};
const createCompany = (data) => {
  return config.axios.post(config.PERSON_BASE_URL + "/legal-persons", data);
};
const updateCompany = (data) => {
  return config.axios.put(config.PERSON_BASE_URL + "/legal-persons", data);
};
const getAllCompanyProperties = () => {
  return config.axios.get(
    config.PERSON_BASE_URL + "/properties-by-person-type?personType=LP"
  );
};
const createCompanyProprety = (data) => {
  return config.axios.post(
    config.PERSON_BASE_URL + "/properties/create-new-property",
    { personType: "LP", ...data }
  );
};
// TODO
const deleteCompanyProperty = (id) => {
  return config.axios.delete(config.PERSON_BASE_URL + "/properties/LP/" + id);
};

const addPropertyToCompany = (personId, data) => {
  return config.axios.post(
    config.PERSON_BASE_URL + "/legal-person/" + personId + "/add-property",
    data
  );
};
const updatePropertyToCompany = (personId, data) => {
  return config.axios.post(
    config.PERSON_BASE_URL + "/legal-person/" + personId + "/update-property",
    data
  );
};
const addRelationToCompany = (personId, data) => {
  return config.axios.post(
    config.PERSON_BASE_URL + "/legal-person/" + personId + "/add-relation",
    data
  );
};
export {
  getAllCompanies,
  getCompanyDetails,
  createCompany,
  updateCompany,
  getAllCompanyProperties,
  createCompanyProprety,
  deleteCompanyProperty,
  addPropertyToCompany,
  updatePropertyToCompany,
  addRelationToCompany
};
