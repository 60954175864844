import { useState } from "react";

const useAddElement = (element, createElement, onSuccess) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const handleSubmit = async () => {
    if (!element) {
      setSubmitError("Element cannot be null or undefined.");
      return;
    }

    setIsSubmitting(true);
    setSubmitError(null);

    try {
      const response = await createElement(element);
      onSuccess();
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      setSubmitError(error?.message || "An unexpected error occurred.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return { isSubmitting, submitError, handleSubmit };
};

export default useAddElement;
