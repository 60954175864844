import React, { useState } from "react";
import {
  Header,
  Button,
  Table,
  Status,
  SidePanel,
  Snackbar,
  Accordeon,
  InputWithActions
} from "iosis-storybook/dist/bundle";
import { IoStarOutline } from "react-icons/io5";
import { IoMdAdd } from "react-icons/io";
import AddOfferContent from "./AddOfferContent";
import { IoCheckmarkOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { MdModeEditOutline } from "react-icons/md";

const Offers = () => {
  const [showAddOffer, setShowAddOffer] = useState(false);
  const [offersArchived, setOffersArchived] = useState(false);
  const [openSidePanelEditOffer, setOpenedSidePanelEditOffer] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState({});
  const [copySelectedOffer, setCopySelectedOffer] = useState({});
  const [offers, setOffers] = useState([
    {
      id: "1234567890",
      name: "Basic",
      startDate: "23 December 2024",
      endDate: "23 December 2024",
      pricing: [
        { periodType: "ONESHOT", currency: "€", price: 200 },
        { periodType: "MONTH", currency: "€", price: 200 }
      ],
      status: "DRAFT",
      createdAt: "23 Décembre 2024",
      product: "Assurance véhicule",
      updatedAt: "20 Août 2024",
      config: {
        power: 100,
        type: "Voiture",
        warranties: ["vol", "vandalisme", "Bris de glace"],
        maxCirculationDate: "23 Décembre 2024",
        technicalAssitance: true
      }
    },
    {
      id: "1134567890",
      name: "Basic",
      startDate: "23 December 2024",
      endDate: "23 December 2024",
      pricing: [{ periodType: "ONESHOT", currency: "€", price: 200 }],
      status: "ACTIVE",
      createdAt: "23 Décembre 2024",
      product: "Assurance véhicule",
      updatedAt: "16 Août 2024",
      config: {
        power: 100,
        type: "Voiture",
        warranties: ["vol", "vandalisme", "Bris de glace"],
        maxCirculationDate: "23 Décembre 2024",
        technicalAssitance: false
      }
    },
    {
      id: "1244567890",
      name: "Basic",
      startDate: "23 December 2024",
      endDate: "23 December 2024",
      pricing: [{ periodType: "ONESHOT", currency: "€", price: 200 }],
      status: "DISABLED",
      createdAt: "23 Décembre 2024",
      product: "Assurance véhicule",
      updatedAt: "10 Juillet 2024",
      config: {
        power: 100,
        type: "Voiture",
        warranties: ["vol", "vandalisme", "Bris de glace"],
        maxCirculationDate: "23 Décembre 2024",
        technicalAssitance: true
      }
    }
  ]);

  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);
  const [isUpdatingStartDate, setIsUpdatingStartDate] = useState(false);
  const [isUpdatingEndDate, setIsUpdatingEndDate] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const PricingType = {
    ONESHOT: "",
    MONTH: "/Mois",
    YEAR: "/An"
  };

  const StatusList = {
    ACTIVE: { type: "complete", title: "Activé" },
    DRAFT: { type: "draft", title: "Brouillon" },
    DISABLED: { type: "danger", title: "Désactivé" }
  };

  const columns = [
    {
      key: "id",
      label: "référence"
    },
    {
      key: "name",
      label: "Nom de l'offre"
    },
    {
      key: "startDate",
      label: "Date de début"
    },
    {
      key: "endDate",
      label: "Date de fin"
    },
    {
      key: "pricing",
      label: "Tarif",
      getter: (value) => (
        <div className='columnn'>
          {value.map((el) => (
            <span>
              {el.price + " " + el.currency + PricingType[el.periodType]}
            </span>
          ))}
        </div>
      )
    },
    {
      key: "status",
      label: "Statut",
      getter: (value) => (
        <Status
          type={StatusList[value].type}
          title={StatusList[value].title}
          forTable
        />
      )
    },
    {
      key: "status",
      label: "Action",
      getter: (status) => {
        switch (status) {
          case "DISABLED":
            return (
              <Button
                styled='tertiary'
                label='Aucune action'
                onClick={(e) => e.stopPropagation()}
                disabled
              />
            );
          case "ACTIVE":
            return (
              <Button
                styled='tertiary'
                label='Désactiver'
                onClick={(e) => e.stopPropagation()}
              />
            );
          case "DRAFT":
            return (
              <Button
                styled='tertiary'
                label='Compléter'
                onClick={(e) => e.stopPropagation()}
              />
            );
          default:
            return <></>;
        }
      }
    }
  ];

  const onArchive = () => {
    setOffersArchived(true);
  };

  const handleEditOffer = (offer) => {
    if (openSidePanelEditOffer) {
      setOpenedSidePanelEditOffer(false);
    }
    setOpenedSidePanelEditOffer(true);
    setCopySelectedOffer(offer);
    setSelectedOffer(offer);
  };

  const infosAccordion = () => (
    <>
      <div className=''>
        <span className='text-xs-bold color-neutral-700'>Créé le</span>
        <div className='text-sm-regular color-neutral-600'>
          {selectedOffer.createdAt}
        </div>
      </div>
      <div className='mt-2'>
        <span className='text-xs-bold color-neutral-700'>Mis à jour le </span>
        <div className='text-sm-regular color-neutral-600'>
          {selectedOffer.updatedAt}
        </div>
      </div>
      <div className='mt-2'>
        <span className='text-xs-bold color-neutral-700'>Produit</span>
        <div className='text-sm-regular color-neutral-600'>
          {selectedOffer.product}
        </div>
      </div>
      <div className='mt-2'>
        {isUpdatingStartDate ||
        (selectedOffer.hasOwnProperty("startDate") &&
          selectedOffer.startDate !== "") ? (
          <InputWithActions
            value={copySelectedOffer.startDate}
            label='Date de début'
            button={<MdModeEditOutline />}
            mainEditingButton={<IoCheckmarkOutline size={10} />}
            onClickMain={() => {
              setSelectedOffer(JSON.parse(JSON.stringify(copySelectedOffer)));
              handleUpdateOffer(copySelectedOffer);
              setIsUpdatingStartDate(false);
            }}
            secondEditingButton={<IoCloseOutline size={10} />}
            onClickSecond={() => {
              setCopySelectedOffer(JSON.parse(JSON.stringify(selectedOffer)));
              setIsUpdatingStartDate(false);
            }}
            onChange={(value) => {
              setCopySelectedOffer({
                ...copySelectedOffer,
                startDate: `${value.day+" "+value.month+" "+value.year}`
              });
            }}
            typing={!selectedOffer.hasOwnProperty("startDate") && true}
            isDate={true}
            years={[2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028]}
          />
        ) : (
          <div>
            <div className='text-xs-bold color-neutral-700'>Date de début</div>
            <Button
              styled='tertiary'
              label='Ajouter'
              onClick={() => {
                setIsUpdatingStartDate(true);
              }}
              endIcon={<IoMdAdd />}
            />
          </div>
        )}
      </div>
      <div className='mt-2'>
        {isUpdatingEndDate ||
        (selectedOffer.hasOwnProperty("endDate") &&
          selectedOffer.startDate !== "") ? (
          <InputWithActions
            value={copySelectedOffer.endDate}
            label='Date de fin'
            button={<MdModeEditOutline />}
            mainEditingButton={<IoCheckmarkOutline size={10} />}
            onClickMain={() => {
              setSelectedOffer(JSON.parse(JSON.stringify(copySelectedOffer)));
              handleUpdateOffer(copySelectedOffer);
              setIsUpdatingEndDate(false);
            }}
            secondEditingButton={<IoCloseOutline size={10} />}
            onClickSecond={() => {
              setCopySelectedOffer(JSON.parse(JSON.stringify(selectedOffer)));
              setIsUpdatingEndDate(false);
            }}
            onChange={(value) => {
              setCopySelectedOffer({
                ...copySelectedOffer,
                endDate: `${value.day+" "+value.month+" "+value.year}`
              });
            }}
            typing={!selectedOffer.hasOwnProperty("endDate") && true}
            isDate={true}
            years={[2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028]}
          />
        ) : (
          <div>
            <div className='text-xs-bold color-neutral-700'>Date de fin</div>
            <Button
              styled='tertiary'
              label='Ajouter'
              onClick={() => {
                setIsUpdatingEndDate(true);
              }}
              endIcon={<IoMdAdd />}
            />
          </div>
        )}
      </div>
    </>
  );

  const configAccordion = () => (
    <>
      <div>
        <span className='text-xs-bold color-neutral-700'>Puissance</span>
        <div className='text-sm-regular color-neutral-600'>
          {selectedOffer.config.power}
        </div>
      </div>
      <div className="mt-2">
        <span className='text-xs-bold color-neutral-700'>Type de véhicule</span>
        <div className='text-sm-regular color-neutral-600'>
          {selectedOffer.config.type}
        </div>
      </div>
      <div className='mt-2'>
        <span className='text-xs-bold color-neutral-700'>
          Garanties prises en charge
        </span>
        <div className='text-sm-regular color-neutral-600'>
          {selectedOffer.config.warranties}
        </div>
      </div>
      <div className='mt-2'>
        <span className='text-xs-bold color-neutral-700'>
          Date maximale de mise en circulation
        </span>
        <div className='text-sm-regular color-neutral-600'>
          {selectedOffer.config.maxCirculationDate}
        </div>
      </div>
      <div className='mt-2'>
        <span className='text-xs-bold color-neutral-700'>
          Assistance technique
        </span>
        <div className='text-sm-regular color-neutral-600'>
          {selectedOffer.config.technicalAssitance ? "Oui" : "Non"}
        </div>
      </div>
    </>
  );

  const tarificationAccordion = () => (
    <div>
      {selectedOffer.pricing.map((price, index) => (
        <div key={index} className='tarifications'>
          <div className='types'>
            <div className='text-xs-bold color-neutral-600'>Type</div>
            <div className='text-sm-regular color-neutral-1000 '>
              {price.periodType === "ONESHOT"
                ? "Paiement comptant"
                : "Paiement mensuel"}
            </div>
          </div>
          <div className='prices'>
            <div className='text-xs-bold color-neutral-600'>Montant</div>
            <div className='text-sm-regular color-neutral-1000 '>
              {price.price} {price.currency}{" "}
              {price.periodType === "MONTH" && "/Mois"}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const sideBarDisplayEditContent = () => (
    <>
      <div className='wrapper'>
        <div>
          <span className='text-sm-semibold color-neutral-600'>
            Référence:{" "}
          </span>
          <span className='text-sm-regular color-neutral-1000'>
            {selectedOffer.id}
          </span>
        </div>
        <div className='offers-status'>
          <div className='offers-name'>
            {isUpdatingStatus ||
            (selectedOffer.hasOwnProperty("name") &&
              selectedOffer.name !== "") ? (
              <InputWithActions
                value={copySelectedOffer.name}
                button={<MdModeEditOutline />}
                mainEditingButton={<IoCheckmarkOutline size={10} />}
                onClickMain={() => {
                  setSelectedOffer(
                    JSON.parse(JSON.stringify(copySelectedOffer))
                  );
                  handleUpdateOffer(copySelectedOffer);
                  setIsUpdatingStatus(false);
                }}
                secondEditingButton={<IoCloseOutline size={10} />}
                onClickSecond={() => {
                  setCopySelectedOffer(
                    JSON.parse(JSON.stringify(selectedOffer))
                  );
                  setIsUpdatingStatus(false);
                }}
                onClickEdit={(status) => {
                  setIsUpdatingStatus(status);
                }}
                onChange={(value) => {
                  setCopySelectedOffer({
                    ...copySelectedOffer,
                    name: value
                  });
                }}
                typing={!selectedOffer.hasOwnProperty("name") && true}
                size={"medium"}
              />
            ) : (
              <div>
                <Button
                  styled='tertiary'
                  label='Ajouter'
                  onClick={() => {
                    setIsUpdatingStatus(true);
                  }}
                  endIcon={<IoMdAdd />}
                />
              </div>
            )}
          </div>

          {!isUpdatingStatus && (
            <div id='status-tags' className={`status-tag`}>
              <Status
                type={StatusList[selectedOffer.status].type}
                title={StatusList[selectedOffer.status].title}
                forTable
              />
            </div>
          )}
        </div>
      </div>
      <div className='divider' />
      <div>
        <Accordeon
          title={"Informations"}
          children={infosAccordion()}
          isChild={true}
        />
      </div>
      <div className='divider' />
      <div>
        <Accordeon
          title={"Configuration"}
          children={configAccordion()}
          isChild={true}
        />
      </div>
      <div className='divider' />
      <div>
        <Accordeon
          title={"Tarification"}
          children={tarificationAccordion()}
          isChild={true}
        />
      </div>
    </>
  );

  const handleUpdateOffer = (offer) => {
    const updatedItems = offers.map((item) => {
      if (item.id === offer.id) {
        return offer;
      } else {
        return item;
      }
    });
    setOffers(updatedItems);
  };

  const handleCloseEditSidePanel = () =>{
    setOpenedSidePanelEditOffer(false)
    setCopySelectedOffer({})
  }

  return (
    <div className='contract-page-container'>
      {offersArchived && (
        <Snackbar
          type='success'
          text="L'offre a été archivée avec succès"
          size='small'
          withLink={true}
          textLink='Annuler'
          autoClose={8000}
        />
      )}
      {showAddOffer && (
        <SidePanel
          title='Créer une offre'
          onClose={() => {
            setShowAddOffer(false);
          }}
          primaryButton={
            <Button
              styled='primary'
              label={activeTab === 2 ? 'Créer une offre' : 'Suivant'}
              onClick={() => {
                if(activeTab === 2){
                  setShowAddOffer(false);
                }
                else{
                  setActiveTab(activeTab+1)
                }
              }}
              size={"md"}
              startIcon={<IoMdAdd size={22}/>}
            />
          }
          secondaryButton={
            <Button
              styled='tertiary'
              label={activeTab === 0 ? 'Annuler' : 'Précedent'}
              onClick={() => {
                if(activeTab === 0){
                  setShowAddOffer(false);
                }
                else{
                  setActiveTab(activeTab-1)
                }
              }}
              size={"md"}
            />
          }
          content={<AddOfferContent activeTab={activeTab} setActiveTab={setActiveTab}/>}
        />
      )}
      {openSidePanelEditOffer && (
        <SidePanel
          title={selectedOffer.id}
          category='Offers'
          onClose={() => handleCloseEditSidePanel()}
          content={sideBarDisplayEditContent()}
          onClickPrimary={handleUpdateOffer}
        />
      )}
      <Header
        title='Offres'
        subtitle='Lorem ipsum dolor sit amet, consectetur adipiscing elit donec.'
        titleIcon={<IoStarOutline color='#62AF98' />}
        primaryButton={
          offers.length > 0 && (
            <Button
              styled='primary'
              label='Créer une offre'
              startIcon={<IoMdAdd size={22} />}
              onClick={() => {
                setShowAddOffer(true);
              }}
              size={"md"}
            />
          )
        }
      />
      <div className='container'>
        <div>
          <Table
            pageSize={10}
            setPageSize={() => {}}
            currentPage={1}
            setCurrentPage={() => {}}
            pageSizes={[10, 20, 50]}
            entriesCount={offers.length}
            columns={columns}
            data={offers}
            withSelect
            onRowClick={(offer) => {
              handleEditOffer(offer);
            }}
            onArchive={onArchive}
            emptyPageTitle={"Il n’y a aucune offre dans la liste"}
            emptyPageDesc={"Cliquez sur “Créer une offre” pour commencer"}
            onClickEmptyPage={() => {}}
            emptyPageTextButton={"Créer une offre"}
            selectedRowId={copySelectedOffer?.id}
          />
        </div>
      </div>
    </div>
  );
};

export default Offers;
