export function getCurrentDay() {
    const date = new Date();
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return ` ${dayOfMonth} ${month} ${year}`;
}

export function generatePassword(length){
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789*$&+-';
    for (let i = 0; i < length; i++) {
        const randomInd = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomInd);
    }
    
    return result
}