import { useState, useCallback, useEffect } from "react";

const useFetchData = (getData, page, pageSize, sort) => {
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [entriesCount, setEntriesCount] = useState(0);

  const handleFetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await getData(page, pageSize, sort);
      setDataList(response.data.content);
      setEntriesCount(response.data.totalElements);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }, [getData, page, pageSize, sort]);

  useEffect(() => {
    handleFetchData();
  }, [handleFetchData]);

  return { dataList, entriesCount, isLoading, error, refetch: handleFetchData };
};

export default useFetchData;
