import React, { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { GoStar } from "react-icons/go";
import {
  Header,
  Table,
  Snackbar,
  SidePanel,
  Input,
  Button
} from "iosis-storybook/dist/bundle";
import { ReactComponent as LabelSVG } from "../../img/icons/code.svg";
import {
  getAllRelations,
  createRelation,
  archiveRelation
} from "./services.js";
import useFetchData from "../../customHooks/useFetchData";
import useAddElement from "../../customHooks/useAddElement.js";
import { formatDate } from "../../utils/utilities";

const Relations = () => {
  const [openSidePanelAdd, setOpenedSidePanelAdd] = useState(false);
  const [textSnackBar, setTextSnackBar] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [validateForm, setValidateForm] = useState(false);
  const [relationsArchived, setRelationsArchived] = useState(false);
  const [relation, setRelation] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("");
  const { dataList, entriesCount, isLoading, error, refetch } = useFetchData(
    getAllRelations,
    page,
    pageSize,
    sort
  );
  const { isSubmitting, submitError, handleSubmit } = useAddElement(
    relation,
    createRelation,
    refetch
  );

  useEffect(() => {
    if (relation && relation.label && relation.code) {
      setValidateForm(true);
    } else {
      setValidateForm(false);
    }
  }, [relation]);

  const tableColumns = [
    { label: "Référence", key: "id", getter: (value) => value.slice(0, 8) },
    { label: "Label", key: "label" },
    { label: "Code", key: "code" },
    {
      label: "Créé le",
      key: "creationDate",
      getter: (date) => formatDate(date)
    }
  ];

  const sideBarAddContent = () => (
    <>
      <div className='content-input relation-label'>
        <div className='label'>
          <Input
            placeholder={"Saisir"}
            label={"Label"}
            type='text'
            description=''
            disabled={false}
            errorMessage=''
            required={false}
            onChange={(label) => {
              setRelation({ ...relation, label: label });
            }}
          />
        </div>
        <div className=''>
          <LabelSVG
            onClick={() => {
              console.log("clicked");
            }}
            className='label-svg'
          />
        </div>
      </div>
      <div className='content-input'>
        <Input
          placeholder={"Saisir"}
          label={"Code"}
          type='text'
          description=''
          disabled={false}
          errorMessage=''
          required={false}
          onChange={(code) => {
            setRelation({ ...relation, code: code });
          }}
        />
      </div>
    </>
  );

  const handleAddRelation = async () => {
    await handleSubmit();
    if (submitError) {
      setOpenedSidePanelAdd(false);
      setTextSnackBar("Erreur lors de l'ajout!");
      setShowSnackBar(true);
    } else {
      setOpenedSidePanelAdd(false);
      setTextSnackBar("Une relation a été ajoutée avec succès!");
      setShowSnackBar(true);
    }
    setRelation({});
    setValidateForm(false);
  };

  const handleArchiveRelation = async (ids) => {
    try {
      await archiveRelation(ids);
      setRelationsArchived(true);
      setTextSnackBar("La relation a été archivée avec succès");
      refetch();
    } catch {
      setTextSnackBar("Erreur lors de l'archivage!");
      setRelationsArchived(true);
    }
  };

  return (
    <div className='relations-container'>
      {openSidePanelAdd && (
        <SidePanel
          title='Créer une relation'
          onClose={() => setOpenedSidePanelAdd(false)}
          content={sideBarAddContent()}
          primaryButton={
            <Button
              styled='primary'
              label='Créer'
              onClick={handleAddRelation}
              startIcon={<IoMdAdd size={22} />}
              blocked={!validateForm}
              size={"md"}
            />
          }
          secondaryButton={
            <Button
              styled='tertiary'
              label='Annuler'
              onClick={() => setOpenedSidePanelAdd(false)}
              size={"md"}
            />
          }
        />
      )}
      {showSnackBar && (
        <Snackbar
          type={submitError ? "danger" : "success"}
          title={textSnackBar}
          size='small'
          autoClose={8000}
          closeCallBack={(state) => {
            setShowSnackBar(false);
          }}
        />
      )}
      {relationsArchived && (
        <Snackbar
          type='success'
          text={textSnackBar}
          size='small'
          withLink={true}
          textLink='Annuler'
          autoClose={5000}
          closeCallBack={(state) => {
            setShowSnackBar(false);
          }}
        />
      )}
      <Header
        title={"Relations"}
        subtitle={
          "Créer ici les relations pour pouvoir lier les personnes entre elles"
        }
        titleIcon={<GoStar color='#62AF98' />}
        primaryButton={
          dataList.length > 0 && (
            <Button
              styled='primary'
              label='Créer une relation'
              startIcon={<IoMdAdd size={22} />}
              onClick={() => {
                setOpenedSidePanelAdd(true);
              }}
            />
          )
        }
      />
      <div className='content-wrapper'>
        <Table
          isLoading={isLoading}
          onRowClick={() => {}}
          withSelect={true}
          pageSize={pageSize}
          setPageSize={(size) => {
            setPageSize(size);
          }}
          currentPage={page}
          setCurrentPage={(page) => {
            setPage(page);
          }}
          setSort={setSort}
          entriesCount={entriesCount}
          emptyPageTextButton='Créer une relation'
          columns={tableColumns}
          data={dataList}
          emptyPageTitle='Il n’y a aucune relation crée dans la plateforme'
          emptyPageDesc='Cliquez sur “Créer une relation” pour commencer'
          onClickEmptyPage={() => {
            setOpenedSidePanelAdd(true);
          }}
          pageSizes={[10, 20, 50]}
          onArchive={(ids) => {
            handleArchiveRelation(ids);
          }}
        />
      </div>
    </div>
  );
};

export default Relations;
