import React, { useEffect, useState } from "react";
import {
  Header,
  Button,
  Table,
  SidePanel,
  Snackbar
} from "iosis-storybook/dist/bundle";
import { GoStar } from "react-icons/go";
import { IoMdAdd } from "react-icons/io";
import AddPropertyContent from "./AddPropertyContent";
const Properties = () => {
  const [propertyList, setPropertyList] = useState([
    {
      id: 1234,
      label: "Statut",
      code: "Status",
      entity: ["Personnes", "Contrats"],
      type: "Chaîne de caractère",
      idProperty: 1,
      condition: "Min: 3 - Max: 15"
    },
    {
      id: 1235,
      label: "Statut",
      code: "Status",
      entity: ["Personnes", "Contrats"],
      type: "Chaîne de caractère",
      idProperty: 1,
      condition: "Min: 3 - Max: 15"
    }
  ]);

  const [propertyPanel, setPropertyPanel] = useState("none");
  const [propertyArchived, setPropertyArchived] = useState(false);

  const [property, setProperty] = useState({});
  const columns = [
    {
      key: "id",
      label: "Référence"
    },

    {
      key: "label",
      label: "Label"
    },
    {
      key: "code",
      label: "code"
    },
    {
      key: "entity",
      label: "Entité",
      getter: (entities) => entities.join(" ; ")
    },
    {
      key: "type",
      label: "type"
    },
    {
      key: "condition",
      label: "condition"
    },
    {
      key: "id",
      label: "Actions",
      getter: (id) => (
        <Button
          styled={"tertiary"}
          label='Modifier'
          size={"sm"}
          onClick={(event) => {
            event.stopPropagation();
            const selectedProperty = propertyList.find(
              (property) => property.id === id
            );
            if (selectedProperty) {
              setProperty(selectedProperty);
            }
            setPropertyPanel("detail");
          }}
        />
      )
    }
  ];
  const onArchive = (itemsId) => {
    setPropertyList((prevList) =>
      prevList.filter((item) => !itemsId.includes(item.id))
    );
    setPropertyArchived(true);
  };
  return (
    <>
      <div className='property-list-container'>
        {propertyArchived && (
          <Snackbar
            type='success'
            text='La propriété a été archivée avec succès'
            size='small'
            withLink={true}
            textLink='Annuler'
            autoClose={8000}
          />
        )}
        <Header
          title={"Propriétés"}
          subtitle={
            "Créez ici les propriétés qui figureront dans les fiches des personnes, des entreprises et des contrats"
          }
          titleIcon={<GoStar color='#62AF98' />}
          primaryButton={
            propertyList.length !== 0 && (
              <Button
                styled='primary'
                label='Créer une propriété'
                startIcon={<IoMdAdd size={22} />}
                onClick={() => {
                  setPropertyPanel("add");
                }}
                size={"md"}
              />
            )
          }
        />
        <div className='container'>
          <Table
            pageSize={10}
            columns={columns}
            data={propertyList}
            entriesCount={propertyList.length}
            pageSizes={[10, 20, 50]}
            setPageSize={() => {}}
            emptyPageTitle={"Il n’y a aucune propriété créée sur la plateforme"}
            emptyPageDesc={"Cliquez sur “Créer une propriété” pour commencer"}
            onClickEmptyPage={() => {
              setPropertyPanel((prevState) => ({
                ...prevState,
                add: true
              }));
            }}
            emptyPageTextButton={"Créer une propriété"}
            withSelect={true}
            onArchive={(items) => {
              onArchive(items);
            }}
          />
        </div>
        {propertyPanel !== "none" && (
          <SidePanel
            title={
              propertyPanel === "add"
                ? "Créer une propriété"
                : "Modifier une propriété"
            }
            onClose={() => setPropertyPanel("none")}
            content={
              <AddPropertyContent
                mode={propertyPanel}
                property={property}
                setProperty={setProperty}
              />
            }
            primaryButton={
              <Button
                styled={"primary"}
                label={propertyPanel === "add" ? "Créer" : "Mettre à jour"}
                size={"md"}
                startIcon={propertyPanel === "add" ? <IoMdAdd size={22} /> : ""}
                onClick={() => console.log(property)}
              />
            }
            secondaryButton={
              <Button
                styled={"tertiary"}
                label={"Annuler"}
                size={"md"}
                onClick={() => setPropertyPanel("none")}
              />
            }
          />
        )}
      </div>
    </>
  );
};

export default Properties;
