import React, { useEffect, useState } from "react";
// import "./personnes.scss";
import {
  Select,
  Button,
  Accordeon,
  InputWithActions,
  Tabs
} from "iosis-storybook/dist/bundle";
import { IoMdAdd } from "react-icons/io";
import noRelation from "../../img/no-relations.svg";
import PropTypes from "prop-types";
import { formatDate } from "../../utils/utilities";
import { getAllActiveRelations } from "../relations/services.js";
import { getAllPersons } from "./services";
import { getAllCompanies } from "../companies/services";
import { addRelationToPerson } from "./services";
const relationGenders = {
  entreprise: "Entreprise",
  person: "Personne"
};

const Relations = ({
  selectedElement,
  setSelectedElement,
  relations,
  refreshData
}) => {
  const [addRelation, setAddRelation] = useState(false);
  const [relationGender, setRelationGender] = useState(
    relationGenders.entreprise
  );
  const [relationType, setRelationType] = useState(false);
  const [relationWith, setRelationWith] = useState("");
  const [selectTypeData, setSelectTypeData] = useState([]);
  const [persons, setPersons] = useState([]);

  const handleAddRelation = async () => {
    let data = {
      targetPerson: relationWith.id,
      relation: relationType.id
    };
    try {
      const response = await addRelationToPerson(selectedElement.id, data);
      if (response.status === 200) {
        setAddRelation(false);
        refreshData();
      }
    } catch (error) {
      console.error("Error adding relations:", error);
    }
  };
  const fetchAllRelations = async () => {
    try {
      const response = await getAllActiveRelations();
      const updatedValues = response.data.content.map((element) => ({
        ...element,
        value: element.id
      }));

      setSelectTypeData(updatedValues);
    } catch (error) {
      console.error("Error fetching relations:", error);
    }
  };

  useEffect(() => {
    fetchAllRelations();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      if (relationGender === relationGenders.entreprise) {
        try {
          const response = await getAllCompanies(1, 50);
          const companies = response.data.content.map((element) => ({
            ...element,
            value: element.id,
            label: element.name
          }));
          setPersons(companies);
        } catch (error) {
          console.error("Error fetching companies:", error);
        }
      } else if (relationGender === relationGenders.person) {
        try {
          const response = await getAllPersons(1, 50);
          const persons = response.data.content.map((element) => ({
            ...element,
            value: element.id,
            label: element.firstName + " " + element.lastName
          }));
          setPersons(persons);
        } catch (error) {
          console.error("Error fetching persons:", error);
        }
      }
    };

    fetchData();
  }, [relationGender]);
  const relationWindow = () => (
    <div className='mt-5'>
      <div className='coll-6'>
        <Tabs
          items={["Entreprise", "Personne"]}
          onSwitch={(value) => {
            setRelationGender(value);
            setRelationWith("");
          }}
        />
      </div>
      <div className='content-input mt-3'>
        <Select
          label={"Type de relation"}
          dataList={selectTypeData}
          placeholder={"Aucune relation sélectionnée"}
          disabled={false}
          onSelectItem={(el) => {
            setRelationType(el);
          }}
        />
      </div>
      <div className='content-input mt-3'>
        <Select
          label={"Avec"}
          dataList={persons}
          placeholder={"Choisir parmi la liste"}
          disabled={false}
          onSelectItem={(el) => {
            setRelationWith(el);
          }}
        />
      </div>
      <div className='content-input roww align-center mt-5'>
        <Button
          styled='primary'
          label='Ajouter une relation'
          onClick={() => {
            handleAddRelation();
          }}
          endIcon={<IoMdAdd />}
        />
        <div className='ml-5'>
          <Button
            styled='tertiary'
            label='Annuler'
            onClick={() => {
              setAddRelation(false);
            }}
          />
        </div>
      </div>
    </div>
  );

  const accordionInfos = () => {
    if (relations && Object.keys(relations).length > 0) {
      return (
        <>
          <div>
            {Object.entries(relations).map(([relationType, relationArray]) =>
              relationArray.map((relation, index) => (
                <div key={`${relationType}-${index}`} className='relations'>
                  {relation.hasOwnProperty("property1") ? (
                    <div className='relation-names text-sm-semibold color-primary-700'>
                      {selectedElement.gender === "Monsieur" ? "Mr " : "Mme "}
                      {selectedElement.firstName} {selectedElement.lastName}
                    </div>
                  ) : (
                    <div className='relation-names text-sm-semibold color-primary-700'>
                      {relation.name
                        ? relation.name
                        : relation.firstName + " " + relation.lastName}
                    </div>
                  )}
                  <div className='relation-types'>
                    <div className='text-xs-bold color-neutral-600'>
                      Type de relation
                    </div>
                    <div className='text-sm-regular color-neutral-1000'>
                      {relationType}
                    </div>
                    <div className='text-xs-bold color-neutral-600 mt-2'>
                      Date de création de la relation
                    </div>
                    <div className='text-sm-regular color-neutral-1000'>
                      {formatDate(relation.creationDate)}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>

          {addRelation && relationWindow()}
        </>
      );
    } else {
      return (
        <div className='relation-empty-accordion'>
          {!addRelation && (
            <>
              <div className='relation-empty-accordion mt-5'>
                <img src={noRelation} alt={"relations"} />
              </div>
              <div className='relation-empty-accordion text-xs-medium mt-5'>
                Il n’y a actuellement aucune relation avec cette personne
              </div>
              <div className='relation-empty-accordion relation-button mt-5'>
                <Button
                  styled='primary'
                  label='Ajouter une relation'
                  onClick={() => setAddRelation(true)}
                  startIcon={<IoMdAdd />}
                />
              </div>
            </>
          )}
          {addRelation && relationWindow()}
        </div>
      );
    }
  };
  return (
    <div>
      <Accordeon
        title={`Relations (${
          relations && Object.keys(relations).length > 0
            ? Object.keys(relations).length
            : 0
        })`}
        isChild
        children={accordionInfos()}
        action={
          relations && (
            <Button
              label='Ajouter'
              styled='tertiary'
              size='sm'
              startIcon={<IoMdAdd size={22} />}
              onClick={() => {
                setAddRelation(true);
              }}
            />
          )
        }
      />
    </div>
  );
};

export default Relations;

Relations.prototype = {
  relations: PropTypes.object,
  selectedElement: PropTypes.object,
  setSelectedElement: PropTypes.func
};
