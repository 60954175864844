
import {
    Button,
    Input,
    Select
  } from "iosis-storybook/dist/bundle";
  import { IoMdMail } from "react-icons/io";
import { generatePassword } from "../../utils/functions";
import { useState } from "react";

const AddUser = ({user, setUser}) => {
    const [generatedPassword, setGeneratedPassword] = useState("");

    const handleGeneratePassword = () =>{
        setGeneratedPassword(generatePassword(16))
    }
    
    return (
        <div className='user-add'>
          <span className="text-sm-bold">Informations utilisateur</span>
          <div className='content-input'>
            <Input
              placeholder={"Saisir"}
              label={"Prénom"}
              type='text'
              description=''
              disabled={false}
              errorMessage=''
              required={false}
              onChange={(firstName) => {
                setUser({ ...user, firstName: firstName });
              }}
            />
          </div>
          <div className='content-input'>
            <Input
              placeholder={"Saisir"}
              label={"Nom"}
              type='text'
              description=''
              disabled={false}
              errorMessage=''
              required={false}
              onChange={(lastName) => {
                setUser({ ...user, lastName: lastName });
              }}
            />
          </div>
          <div className='content-input'>
            <Input
              placeholder={"Saisir"}
              label={"Adresse e-mail"}
              type='text'
              description=''
              disabled={false}
              errorMessage=''
              required={false}
              onChange={(email) => {
                setUser({ ...user, email: email });
              }}
            />
          </div>

          <div className='content-input'>
            <Select
              label={"Rôle"}
              dataList={[
                { id: 1, value: "admin", label: "Super utilisateur" },
                { id: 2, value: "user", label: "Utilisateur" }
              ]}
              placeholder={"Sélectionner rôle"}
              disabled={false}
              onSelectItem={(el) => {
                setUser({ ...user, role: el.value });
              }}
            />
          </div>

          <div className="mt-10">
            <span className='text-sm-bold color-neutral-800'>
                Mot de passe
            </span>
            <div className='text-xs-medium color-neutral-700'>
                L’utilisateur devra changer son mot de passe lors de la création de son compte
            </div>
          </div>

          <div className="roww justify-space-between align-flex-end">
              <div className='content-input coll-10'>
                  <Input
                  placeholder={"Saisir"}
                  label={"Mot de passe du compte"}
                  type='password'
                  description=''
                  disabled={false}
                  errorMessage=''
                  required={false}
                  value={generatedPassword}
                  onChange={(password) => {
                      setUser({ ...user, password });
                  }}
                  />
              </div>
              <div className="mb-3">
              <Button
                  styled='tertiary'
                  label='Générer'
                  onClick={() => {handleGeneratePassword()}}
                  size={"lg"}
              />
              </div>
          </div>
                    
        </div>
    );
};

export default AddUser;
