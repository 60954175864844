import React, { useEffect, useState } from "react";
import { getAllRoles } from "../contrat/services";
import { formatDate } from "../../utils/utilities";
import {
  Header,
  Button,
  Table,
  Snackbar,
  SidePanel,
} from "iosis-storybook/dist/bundle";
import { IoStarOutline } from "react-icons/io5";
import { IoMdAdd } from "react-icons/io";
import AddRoleContent from "./AddRoleContent";

const Roles = () => {
  const [rolesList, setRolesList] = useState([]);
  const [showAddRole, setShowAddRole] = useState(false);
  const [roleAdded, setRoleAdded] = useState(false);
  const [rolesArchived, setRolesArchived] = useState(false);

  const columns = [
    {
      key: "id",
      label: "Référence",
      getter: (value) => `${value.slice(0, 8)}`,
    },
    {
      key: "label",
      label: "Label",
    },
    {
      key: "code",
      label: "Code",
    },
    {
      key: "creationDate",
      label: "Créé le",
      getter: (value) => {
        return formatDate(value);
      },
    },
  ];

  const onArchive = () => {
    setRolesArchived(true);
  };

  useEffect(() => {
    getAllRoles()
      .then((response) => {
        setRolesList(response.data);
      })
      .catch((e) => console.log(e));
  }, [roleAdded]);
  return (
    <div className="contract-page-container">
      {rolesArchived && (
        <Snackbar
          type="success"
          text="Le rôle a été archivé avec succès"
          size="small"
          withLink={true}
          textLink="Annuler"
          autoClose={8000}
        />
      )}
      {showAddRole && (
        <SidePanel
          title="Créer un rôle"
          onClose={() => {
            setShowAddRole(false);
          }}
          primaryButton={
            <Button
              styled="primary"
              label="Créer"
              onClick={() => {}}
              startIcon={<IoMdAdd size={22} />}
              size={"md"}
            />
          }
          secondaryButton={
            <Button
              styled="tertiary"
              label="Annuler"
              onClick={() => {
                setShowAddRole(false);
              }}
              size={"md"}
            />
          }
          content={<AddRoleContent />}
        />
      )}
      <Header
        title="Rôles"
        subtitle="Créer ici les rôles pour pouvoir les administrer entre les personnes et entreprises"
        titleIcon={<IoStarOutline color="#62AF98" />}
        primaryButton={
          <Button
            styled="primary"
            size={"md"}
            label="Créer un rôle"
            onClick={() => {
              setShowAddRole(true);
            }}
            startIcon={<IoMdAdd size={20} />}
          />
        }
      />
      <div className="container">
        <Table
          pageSize={10}
          setPageSize={() => {}}
          currentPage={1}
          setCurrentPage={() => {}}
          pageSizes={[10, 20, 50]}
          entriesCount={rolesList.length}
          columns={columns}
          data={rolesList}
          withSelect
          onArchive={() => {
            onArchive();
          }}
          onRowClick={() => {}}
          emptyPageTitle={"Il n’y a aucun rôle créé dans la plateforme"}
          emptyPageDesc={"Cliquez sur “Créer une rôle” pour commencer"}
          emptyPageTextButton={"Créer un rôle"}
          onClickEmptyPage={() => {
            setShowAddRole(true);
          }}
        />
      </div>
    </div>
  );
};

export default Roles;
