import { config } from "../../config/config";

const getAllRelations = () => {
  return config.axios.get(config.PERSON_BASE_URL + "/relations");
};
const getAllActiveRelations = () => {
  return config.axios.get(config.PERSON_BASE_URL + "/relations", {
    params: {
      active: true
    }
  });
};
const createRelation = (data) => {
  return config.axios.post(config.PERSON_BASE_URL + "/relations", data);
};

const archiveRelation = (ids) => {
  return config.axios.put(config.PERSON_BASE_URL + "/relations/archive", ids);
};



export {
  getAllRelations,
  createRelation,
  archiveRelation,
  getAllActiveRelations
};
