import React, { useState } from "react";
import { getAllProducts, createProduct, archiveProduct } from "./services";
import useFetchData from "../../customHooks/useFetchData";
import useAddElement from "../../customHooks/useAddElement.js";

import {
  Header,
  Button,
  Table,
  Status,
  SidePanel,
  Snackbar
} from "iosis-storybook/dist/bundle";

import { formatDate } from "../../utils/utilities";
import { GoStar } from "react-icons/go";
import { IoMdAdd } from "react-icons/io";
import AddProductContent from "./AddProductContent";
import DetailProductContent from "./ProductDetailContent";
const Products = () => {
  const [product, setProduct] = useState({});
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [productShown, setProductShown] = useState({
    add: false,
    detail: false
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("");

  const [textSnackBar, setTextSnackBar] = useState("");
  const { dataList, entriesCount, isLoading, error, refetch } = useFetchData(
    getAllProducts,
    page,
    pageSize,
    sort
  );
  const { isSubmitting, submitError, handleSubmit } = useAddElement(
    product,
    createProduct,
    refetch
  );

  const StatusList = {
    ENABLED: { type: "complete", title: "Activé" },
    ARCHIVED: { type: "danger", title: "Archivé" }
  };
  const columns = [
    {
      key: "id",
      label: "Référence",
      getter: (value) => `${value.slice(0, 8)}`
    },

    {
      key: "name",
      label: "Nom du produit"
    },
    {
      key: "lastUpdate",
      label: "Mis à jour le",
      getter: (value) => formatDate(value)
    },
    {
      key: "status",
      label: "Statut",
      getter: (row) => {
        return (
          <Status
            type={StatusList[row].type}
            title={StatusList[row].title}
            forTable
          />
        );
      }
    },
    {
      key: "id",
      label: "Actions",
      getter: (id) => {
        const currentProduct = dataList.find((product) => product.id === id);
        return (
          <Button
            styled={"tertiary"}
            label='Modifier'
            size={"sm"}
            onClick={(event) => {
              event.stopPropagation();
              const selectedProduct = dataList.find(
                (product) => product.id === id
              );
              if (selectedProduct) setSelectedProduct(selectedProduct);
              setProductShown(() => ({
                detail: true,
                add: false
              }));
            }}
            disabled={currentProduct?.status === "ARCHIVED"}
          />
        );
      }
    }
  ];
  const handleAddProduct = async () => {
    setShowSnackBar(true);
    await handleSubmit();
    if (submitError) {
      setTextSnackBar("Erreur lors de l'ajout!");
      setShowSnackBar(true);
    } else {
      setTextSnackBar("Un produit a été ajoutée avec succès !");
      setShowSnackBar(true);
    }
    setProductShown((prevState) => ({
      ...prevState,
      add: false
    }));
    setProduct({});
  };

  const onArchive = async (items) => {
    try {
      await archiveProduct(items);
      refetch();
    } catch (e) {
      console.error("error while archiving products");
    }
  };

  return (
    <>
      <div className='product-list-container'>
        {showSnackBar && (
          <Snackbar
            type={submitError ? "danger" : "success"}
            title={textSnackBar}
            size='small'
            withLink={true}
            textLink='Annuler'
            autoClose={8000}
            closeCallBack={() => {
              setShowSnackBar(false);
            }}
          />
        )}
        <Header
          title={"Produits"}
          subtitle={
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit donec."
          }
          titleIcon={<GoStar color='#62AF98' />}
          primaryButton={
            dataList.length !== 0 && (
              <Button
                styled='primary'
                label='Créer un produit'
                startIcon={<IoMdAdd size={22} />}
                onClick={() => {
                  setProductShown((prevState) => ({
                    ...prevState,
                    add: true
                  }));
                }}
                size={"md"}
              />
            )
          }
        />
        <div className='container'>
          <Table
            pageSize={pageSize}
            columns={columns}
            data={dataList}
            entriesCount={entriesCount}
            isLoading={isLoading}
            onRowClick={(product) => {
              if (product.status === "ENABLED") {
                setProductShown((prevState) => ({
                  ...prevState,
                  detail: true,
                  add: false
                }));
                setSelectedProduct(product);
              }
            }}
            pageSizes={[10, 20, 50]}
            setPageSize={(size) => {
              setPageSize(size);
            }}
            emptyPageTitle={"Il n’y a aucun produit dans la liste"}
            emptyPageDesc={
              "Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum"
            }
            onClickEmptyPage={() => {
              setProductShown((prevState) => ({
                ...prevState,
                add: true
              }));
            }}
            emptyPageTextButton={"Créer un produit"}
            withSelect={true}
            setSort={setSort}
            onArchive={(items) => {
              onArchive(items);
            }}
            selectedRowId={selectedProduct?.id}
          />
        </div>

        {productShown.add && (
          <SidePanel
            title='Créer un produit'
            onClose={() =>
              setProductShown((prevState) => ({
                ...prevState,
                add: false
              }))
            }
            content={
              <AddProductContent
                setProduct={setProduct}
                product={product}
                setSelectedItems={setSelectedItems}
                selectedItems={selectedItems}
              />
            }
            primaryButton={
              <Button
                styled={"primary"}
                label={"Créer"}
                size={"md"}
                startIcon={<IoMdAdd size={22} />}
                loading={isSubmitting}
                onClick={handleAddProduct}
              />
            }
            secondaryButton={
              <Button
                styled={"tertiary"}
                label={"Annuler"}
                size={"md"}
                onClick={() => {
                  setProductShown((prevState) => ({
                    ...prevState,
                    add: false
                  }));
                }}
              />
            }
          />
        )}
        {productShown.detail && (
          <SidePanel
            category='Produits'
            title={`${selectedProduct.id.slice(0, 8)}`}
            onClose={() =>
              setProductShown((prevState) => ({
                ...prevState,
                detail: false
              }))
            }
            content={
              <DetailProductContent
                product={selectedProduct}
                refetch={refetch}
                setTextSnackBar={setTextSnackBar}
                setShowSnackBar={setShowSnackBar}
              />
            }
          />
        )}
      </div>
    </>
  );
  {
  }
};

export default Products;
