import { useState } from "react";

const useEditElement = (element, updateElement, onSuccess, refetch) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editError, setEditError] = useState(null);

  const handleEdit = async () => {
    if (!element) {
      setEditError("Element cannot be null or undefined.");
      return;
    }

    setIsEditing(true);
    setEditError(null);

    try {
      console.log({ element });
      const response = await updateElement(element);
      if (onSuccess) {
        onSuccess(element?.id);
        refetch();
      }
    } catch (error) {
      console.error("Error in handleEdit:", error);
      setEditError(error?.message || "An unexpected error occurred.");
    } finally {
      setIsEditing(false);
    }
  };

  return {
    isEditing,
    editError,
    setEditError,
    handleEdit
  };
};

export default useEditElement;
