import React, { Fragment, useEffect, useRef } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import logo from "../img/iosis-logo.svg";
import { Settings, topOptions } from "../components/sidebar/sidebarData";
import Dashboard from "./Dashboard/Dashboard";
import { NestedSideBar, NavBar } from "iosis-storybook/dist/bundle";

import CreateQuoteAuto from "./quotes/automobile/quoteCreation";
import Register from "./register/Register";
import UserInfo from "./user-info/UserInfo";
import { decodedToken } from "../utils/token";
import { getInitials } from "../utils/utilities";
import { iosisLogout, keycloakLogout } from "../utils/logout";
import leftArrow from "../img/arrow-left.svg";
import rightArrow from "../img/arrow-right.svg";
import GetKeycloakInstance from "../utils/keycloak";
const NavPage = () => {
  const location = useLocation();
  const hideSidebarPaths = ["/quote/new"];
  const navigate = useNavigate();

  const tokenInfo = decodedToken();

  const profileItems = {
    fullName: tokenInfo.name,
    eMail: tokenInfo.email
  };
  const handleLogout = async () => {
    if (process.env.REACT_APP_AUTH === "keycloak") {
      const keycloak = await GetKeycloakInstance();
      return keycloak?.logout(); //{ redirectUri: config.KEYCLOAK_BASE_URL });
    } else if (process.env.REACT_APP_AUTH === "keycloak-iosis") {
      keycloakLogout();
    } else if (process.env.REACT_APP_AUTH === "iosis") {
      iosisLogout();
    }
  };


  const contentRef = useRef(null);

  useEffect(() => {
    const handleWheel = (event) => {
      const content = contentRef.current;
      if (content) {
        content.scrollLeft += event.deltaY;
      }
    };

    contentRef.current.addEventListener('wheel', handleWheel);

    return () => {
      contentRef.current.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <div>
      <NavBar
        settingsItems={Settings}
        profileItems={profileItems}
        logoSrc={logo}
        initials={getInitials(tokenInfo.given_name, tokenInfo.family_name)}
        settingsTitle='paramétres'
        onNavigateSetting={(path) => navigate(path)}
        onClickAccount={() => navigate("user-info")}
        onLogout={handleLogout}
      />
      <div className='main-app-container'>
        {!hideSidebarPaths.includes(location.pathname.slice(0, 10)) && (
          <NestedSideBar
            topOptions={topOptions}
            // bottomOptions={bottomOptions}
            onNavigate={(path) => navigate(path)}
            leftArrow={leftArrow}
            rightArrow={rightArrow}
          />
        )}
        <div className="scroll-content" ref={contentRef}>
          <Routes>
            {topOptions.map((item) =>
              !item?.childs ? (
                <Fragment key={item.path}>
                  <Route
                    key={item.path}
                    path={item.path}
                    Component={item.component}
                  />
                  <Route
                    key={item.detailsPath}
                    path={item.detailsPath}
                    Component={item.detailsComponent}
                  />
                </Fragment>
              ) : (
                item.childs?.map((subItem) => (
                  <Fragment key={subItem.path}>
                    <Route
                      key={subItem.path}
                      path={subItem.path}
                      Component={subItem.component}
                    />
                    <Route
                      key={subItem.detailsPath}
                      path={subItem.detailsPath}
                      Component={subItem.detailsComponent}
                    />
                  </Fragment>
                ))
              )
            )}

            {Settings.map((item, index) => (
              <Route key={index} path={item.path} Component={item.component} />
            ))}
            <Route path='/' Component={Dashboard} />
            <Route path='/register' Component={Register} />
            <Route path='/user-info' Component={UserInfo} />
          </Routes>
        </div>
      <Routes>
        <Route path='/quote/new/:id' Component={CreateQuoteAuto} />
      </Routes>
      </div>
    </div>
  );
};

export default NavPage;
