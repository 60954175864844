import React, { useState } from "react";
import { createRole } from "../contrat/services";
import { Input, Button } from "iosis-storybook/dist/bundle";

const AddRoleContent = () => {
  const [addRoleData, setAddRoleData] = useState({});

  return (
    <div className="columnn gapp-2">
      <div className="roww align-flex-end">
        <div className="coll-11">
          <Input
            label="Label"
            placeholder="Saisir"
            onChange={(value) => {
              setAddRoleData({ ...addRoleData, code: value });
            }}
          />
        </div>
        <span className="coll-1 mb-2">
          <Button
            styled="tertiary"
            label="< / >"
            onClick={() => {}}
            size={"md"}
          />
        </span>
      </div>
      <Input
        label="Code"
        placeholder="Saisir"
        onChange={(value) => {
          setAddRoleData({ ...addRoleData, label: value });
        }}
      />
    </div>
  );
};

export default AddRoleContent;
