import React, { useState, useEffect } from "react";
import {
  Status,
  Accordeon,
  Button,
  InputWithActions,
  Checkbox
} from "iosis-storybook/dist/bundle";
import { updateProduct, getProductDetails } from "./services";
import { ReactComponent as Close } from "../../img/icons/close.svg";
import { formatDate } from "../../utils/utilities";
import { IoMdAdd } from "react-icons/io";
import emtpyList from "../../img/empty-list.svg";
import { MdModeEditOutline } from "react-icons/md";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import useEditElement from "../../customHooks/useEditElement";
const DetailProductContent = ({
  product,
  refetch,
  setTextSnackBar,
  setShowSnackBar
}) => {
  const [productDetails, setProductDetails] = useState(product);
  const [propertyList, setPropertyList] = useState([]);
  const [copyProductDetails, setCopyProductDetails] = useState(product);
  const [isCheckBoxShown, setIsCheckBoxShow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [key, setKey] = useState("");

  const { isEditing, editError, handleEdit } = useEditElement(
    { id: copyProductDetails.id, [key]: copyProductDetails[key] },
    updateProduct,
    getProductDetails,
    refetch
  );

  const handleEditProduct = async () => {
    try {
      await handleEdit();
      setTextSnackBar("Un produit a été modifiée avec succès!");
      setShowSnackBar(true);
      setKey("");
    } catch (e) {
      setTextSnackBar("Erreur lors de la modification!");
      setShowSnackBar(true);
    }
  };

  const getProduct = async (product) => {
    try {
      const response = await getProductDetails(product.id);
      if (response.data) {
        setProductDetails(response.data);
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (e) {
      console.error("Error in getPerson:", e);
      throw e;
    }
  };
  useEffect(() => {
    setCopyProductDetails(productDetails);
  }, [productDetails]);
  useEffect(() => {
    getProduct(product)
      .then((response) => {
        const productData = response.data;
        if (productData.description === null) {
          productData.description = "-";
        }
        if (productData.name === null) {
          productData.name = "-";
        }
      })
      .catch((e) => console.log(e));
  }, [product]);

  useEffect(() => {
    if (!!productDetails.properties) {
      const properties = productDetails.properties.map((el) => {
        return { code: el.code, label: el.label, value: el.propertyType };
      });

      setPropertyList(properties);
    } else {
      setPropertyList([]);
    }
  }, [productDetails]);
  const handleRemoveItem = (code) => {
    setPropertyList((prevItems) =>
      prevItems.filter((item) => item.code !== code)
    );
  };
  const handleMultipleSelect = (item) => {
    setSelectedItems((prevSelectedItems) => {
      const isSelected = prevSelectedItems.some(
        (selectedItem) => selectedItem.code === item.code
      );
      if (isSelected) {
        return prevSelectedItems.filter(
          (selectedItem) => selectedItem.code !== item.code
        );
      } else {
        return [...prevSelectedItems, item];
      }
    });
  };

  const StatusList = {
    ENABLED: { type: "complete", title: "Activé" },
    ARCHIVED: { type: "danger", title: "Archivé" }
  };

  const ProductInfoContent = (
    <div className='columnn gapp-2'>
      <div className='columnn'>
        <span className='text-xs-bold color-neutral-700'>Créé le</span>
        <span className='text-sm-regular color-neutral-600'>
          {formatDate(productDetails.creationDate)}
        </span>
      </div>
      <div className='columnn'>
        <span className='text-xs-bold color-neutral-700'>Mis à jour le</span>
        <span className='text-sm-regular color-neutral-600'>
          {formatDate(productDetails.lastUpdate)}
        </span>
      </div>
      <div className='columnn'>
        <span className='text-xs-bold color-neutral-700'>Description</span>

        <InputWithActions
          value={copyProductDetails.description}
          button={<MdModeEditOutline />}
          mainEditingButton={<IoCheckmarkOutline size={10} />}
          onClickMain={() => {
            handleEditProduct(copyProductDetails);
          }}
          secondEditingButton={<IoCloseOutline size={10} />}
          onClickSecond={() => {
            setKey("");
            setCopyProductDetails({
              ...copyProductDetails,
              description: productDetails.description
            });
          }}
          onChange={(value) => {
            setKey("description");
            setCopyProductDetails({
              ...copyProductDetails,
              description: value
            });
          }}
        />
      </div>
    </div>
  );

  const ProductPropContent = (
    <div className='columnn gapp-2'>
      {propertyList.length ? (
        propertyList.map((el, key) => (
          <div className='roww align-center justify-space-between'>
            <div className='columnn'>
              <span className='text-xs-bold color-neutral-700'>{el.label}</span>
              <span className='text-sm-regular color-neutral-600'>
                {el.value}
              </span>
            </div>
            <Close
              className='close-button'
              onClick={() => {
                handleRemoveItem(el.code);
              }}
            />
          </div>
        ))
      ) : (
        <div className='empty-list-product'>
          <img src={emtpyList} alt='Empty list' />

          <span className='text-xs-medium color-neutral-700'>
            Il n’y a actuellement aucune <br />
            propriétés
          </span>
        </div>
      )}
    </div>
  );
  const offers = [
    {
      name: "Basic",
      type: "Paiement comptant",
      price: "200 € "
    }
  ];
  const ProductOfferContent = (
    <div className='columnn gapp-2'>
      {offers.length ? (
        offers.map((el, key) => (
          <div className='roww align-center'>
            <span className='coll-4 label-offer'>{el.name}</span>
            <div className='coll-8 columnn gapp-2'>
              <div className='columnn'>
                <span className='text-xs-bold color-neutral-600'>Type</span>
                <span className='text-sm-regular color-neutral-1000'>
                  {el.type}
                </span>
              </div>
              <div className='columnn'>
                <span className='text-xs-bold color-neutral-600'>Montant</span>
                <span className='text-sm-regular color-neutral-1000'>
                  {el.price}
                </span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className='empty-list-product'>
          <img src={emtpyList} alt='Empty list' />

          <span className='text-xs-medium color-neutral-700'>
            Il n’y a actuellement aucune <br />
            offres
          </span>
        </div>
      )}
    </div>
  );

  return (
    <div className='columnn'>
      <div className='roww'>
        <span className='text-sm-semibold color-neutral-600'>
          Réference:&nbsp;
        </span>
        <span className='text-sm-regular color-neutral-1000'>
          {product.id.slice(0, 8)}
        </span>
      </div>
      <div className='roww align-center gapp-2'>
        <InputWithActions
          size={"medium"}
          value={copyProductDetails.name}
          button={<MdModeEditOutline />}
          mainEditingButton={<IoCheckmarkOutline size={10} />}
          onClickMain={() => {
            handleEditProduct(copyProductDetails);
          }}
          secondEditingButton={<IoCloseOutline size={10} />}
          onClickSecond={() => {
            setCopyProductDetails({
              ...copyProductDetails,
              name: productDetails.name
            });
            setKey("");
          }}
          onChange={(value) => {
            setKey("name");
            setCopyProductDetails({ ...copyProductDetails, name: value });
          }}
        />
        <span>
          <Status
            type={StatusList[product.status].type}
            title={StatusList[product.status].title}
            forTable={true}
          />
        </span>
      </div>
      <div className='divider-product' />
      <Accordeon title='Informations' isChild children={ProductInfoContent} />
      <div className='property-accordeon'>
        <div className='divider-product' />
        {isCheckBoxShown && propertyList.length !== 0 && (
          <div className='property-checkbox-container'>
            <div className='property-checkboxList'>
              {propertyList.map((item, index) => (
                <div className='checkbox-container'>
                  <Checkbox
                    label={item.label}
                    onChange={() => handleMultipleSelect(item)}
                    checked={selectedItems.some((el) => el.code === item.code)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        <div className='property-accordeon'>
          <Accordeon
            title='Propriétés'
            isChild
            children={ProductPropContent}
            action={
              <Button
                styled={"tertiary"}
                size={"sm"}
                label={"Ajouter une propriété"}
                startIcon={<IoMdAdd size={22} />}
                onClick={() => {
                  setIsCheckBoxShow((prev) => !prev);
                }}
              />
            }
          />
        </div>
        <div className='divider-product' />
      </div>
      <Accordeon title='Offres' isChild children={ProductOfferContent} />
    </div>
  );
};

export default DetailProductContent;
