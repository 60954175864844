import React, { useState, useEffect } from "react";
import { Input, Button, Select } from "iosis-storybook/dist/bundle";
import { ReactComponent as Close } from "../../img/icons/close.svg";
const AddProductContent = ({
  product,
  setProduct,
  selectedItems,
  setSelectedItems
}) => {
  const dataProp = [
    { id: 1, value: "String", label: "Chaîne de caractères" },
    { id: 2, value: "Number", label: "Nombre" },
    { id: 3, value: "Date", label: "Date" },
    { id: 4, value: "List", label: "Liste" },
    { id: 5, value: "Boolean", label: "Boolean" }
  ];
  const handleSelectItem = (items) => {
    setSelectedItems((prevItems) => {
      const prevIds = new Set(prevItems.map((item) => item.id));
      const newItems = items.filter((item) => !prevIds.has(item.id));
      if (newItems.length > 0) {
        return [...prevItems, ...newItems];
      }
      return prevItems;
    });
  };

  const handleRemoveItem = (itemId) => {
    setSelectedItems((prevItems) =>
      prevItems.filter((item) => item.id !== itemId)
    );
  };
  return (
    <div className='product-form-container'>
      <div className='product-info-container'>
        <span className='text-sm-bold color-neutral-800'>Informations</span>

        <Input
          label='Nom du produit'
          placeholder='Saisir'
          type='text'
          onChange={(name) => {
            setProduct({ ...product, name: name });
          }}
        />

        <Input
          label='Description'
          placeholder='Décrire'
          type='textArea'
          onChange={(desc) => {
            setProduct({ ...product, description: desc });
          }}
        />
      </div>
      <div className='product-property-container'>
        <span className='text-sm-bold color-neutral-800'>Propriétés</span>
        <Select
          label='Liste des propriétés'
          dataList={dataProp}
          placeholder='Sélectionner'
          description='Veuillez sélectionner les propriétés que vous souhaitez ajouter au produit'
          multiple
          onSelectItem={handleSelectItem}
          setSelectedMultiValues={setSelectedItems}
          selectedMultiValues={selectedItems}
        />
        <div className='product-property-list'>
          {selectedItems.map((el, index) => (
            <div className='property-element'>
              <div className='property-text'>
                <span className='property-label'>{el.label}</span>
                <span className='property-value'>{el.value}</span>
              </div>
              <Close
                onClick={() => {
                  handleRemoveItem(el.id);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default AddProductContent;
