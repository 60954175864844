import React, { useEffect, useState } from "react";
import {
  Header,
  Table,
  Snackbar,
  SidePanel,
  Input,
  Select,
  Button,
  Accordeon,
  InputWithActions
} from "iosis-storybook/dist/bundle";
import {
  createPerson,
  getAllPersons,
  getPersonDetails,
  updatePerson
} from "./services.js";
import { IoMdMail } from "react-icons/io";
import { GoStar } from "react-icons/go";
import { IoMdAdd } from "react-icons/io";
import { IoCheckmarkOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { MdModeEditOutline } from "react-icons/md";
import Relations from "./Relations";
import { ReactComponent as Copy } from "../../img/icons/link.svg";
import { BsThreeDotsVertical } from "react-icons/bs";
import useFetchData from "../../customHooks/useFetchData";
import useAddElement from "../../customHooks/useAddElement.js";
import useEditElement from "../../customHooks/useEditElement.js";
import { formatDate } from "../../utils/utilities";

const Personnes = () => {
  const [openSidePanelAddPerson, setOpenedSidePanelAddPerson] = useState(false);
  const [openSidePanelEditPerson, setOpenedSidePanelEditPerson] =
    useState(false);
  const [person, setPerson] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState("");
  const [key, setKey] = useState("");
  const [copySelectedPerson, setCopySelectedPerson] = useState({});
  const { dataList, entriesCount, isLoading, error, refetch } = useFetchData(
    getAllPersons,
    page,
    pageSize,
    sort
  );
  const [isBlocked, setIsBlocked] = useState(true);
  const { isSubmitting, submitError, handleSubmit } = useAddElement(
    person,
    createPerson,
    refetch
  );

  const { isEditing, editError, handleEdit } = useEditElement(
    { id: copySelectedPerson.id, [key]: copySelectedPerson[key] },
    updatePerson,
    getPersonDetails,
    refetch
  );

  const [selectedPerson, setSelectedPerson] = useState({});
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [isUpdatingPhone, setIsUpdatingPhone] = useState(false);
  const [isUpdatingAddress, setIsUpdatingAddress] = useState(false);
  const [isUpdatingDate, setIsUpdatingDate] = useState(false);

  const [textSnackBar, setTextSnackBar] = useState("");
  const [mailCopied, setMailCopied] = useState(false);
  const [relations, setRelations] = useState({});
  const showCopiedMsg = () => {
    setMailCopied(true);
    setTimeout(() => setMailCopied(false), 1000);
  };
  useEffect(() => {
    if (
      person &&
      person.firstName &&
      person.lastName &&
      person.email &&
      person.gender
    ) {
      setIsBlocked(false);
    } else {
      setIsBlocked(true);
    }
  }, [person]);

  const tableColumns = [
    { label: "Référence", key: "id", getter: (value) => value.slice(0, 8) },
    {
      label: "Genre",
      key: "gender",
      getter: (value) => (value === "MALE" ? "Monsieur" : "Madame")
    },
    { label: "Prénom", key: "firstName" },
    { label: "Nom", key: "lastName" },
    { label: "E-mail", key: "email" },
    {
      label: "Créé le",
      key: "creationDate",
      getter: (date) => formatDate(date)
    }
  ];

  useEffect(() => {
    setCopySelectedPerson(JSON.parse(JSON.stringify(selectedPerson)));
  }, [selectedPerson]);

  const onClickMain = () => {
    setOpenedSidePanelAddPerson(true);
  };

  const copyToClipBoard = () => {
    navigator.clipboard.writeText(selectedPerson.email);
    showCopiedMsg();
  };

  const handleUpdatePerson = async (person) => {
    try {
      if (selectedPerson !== null){
        handleEdit();
        setTextSnackBar("Une personne a été modifiée avec succès!");
        setShowSnackBar(true);
      }
      
    } catch (e) {
      setTextSnackBar("Erreur lors de la modification!");
      setShowSnackBar(true);
    }
  };

  const getPerson = async (person) => {
    try {
      const response = await getPersonDetails(person.id);
      if (response.data && response.data.personData) {
        setSelectedPerson(response.data.personData);
        setRelations(response.data?.relations);
      } else {
        console.error("Unexpected response structure:", response);
      }
    } catch (e) {
      console.error("Error in getPerson:", e);
      throw e;
    }
  };

  const handleEditPerson = (person) => {
    if (openSidePanelAddPerson) {
      setOpenedSidePanelAddPerson(false);
    }

    setOpenedSidePanelEditPerson(true);
    getPerson(person);
    setCopySelectedPerson(person);
    setSelectedPerson(person);
  };

  const sideBarAddPersonContent = () => (
    <div className='content-container'>
      <div>
        <Select
          label={"Genre"}
          dataList={[
            { id: 1, value: "MALE", label: "Monsieur" },
            { id: 2, value: "FEMALE", label: "Madame" }
          ]}
          placeholder={"Sélectionner"}
          disabled={false}
          onSelectItem={(el) => {
            setPerson({ ...person, gender: el.value });
          }}
        />
      </div>

      <div>
        <Input
          placeholder={"Saisir"}
          label={"Prénom"}
          type='text'
          description=''
          disabled={false}
          errorMessage=''
          required={false}
          onChange={(firstName) => {
            setPerson({ ...person, firstName: firstName });
          }}
        />
      </div>
      <div>
        <Input
          placeholder={"Saisir"}
          label={"Nom"}
          type='text'
          description=''
          disabled={false}
          errorMessage=''
          required={false}
          onChange={(lastName) => {
            setPerson({ ...person, lastName: lastName });
          }}
        />
      </div>
      <div>
        <Input
          placeholder={"Saisir"}
          label={"Adresse e-mail"}
          type='text'
          description=''
          disabled={false}
          errorMessage=''
          required={false}
          iconLeft={<IoMdMail />}
          onChange={(email) => {
            setPerson({ ...person, email: email });
          }}
        />
      </div>
    </div>
  );

  const handleAddPerson = async () => {
    await handleSubmit();
    if (submitError) {
      setOpenedSidePanelAddPerson(false);
      setTextSnackBar("Erreur lors de l'ajout!");
      setShowSnackBar(true);
    } else {
      setOpenedSidePanelAddPerson(false);
      setTextSnackBar("Une personne a été ajoutée avec succès !");
      setShowSnackBar(true);
    }
    setPerson({});
    setIsBlocked(true);
  };

  const accordionInfos = () => (
    <div className='columnn gapp-2'>
      <div className='columnn'>
        <span className='text-xs-bold color-neutral-700'>Créé le</span>
        <div className='text-sm-regular color-neutral-600'>
          {formatDate(selectedPerson.creationDate)}
        </div>
      </div>
      <div className='columnn'>
        {isUpdatingPhone ||
        (selectedPerson.hasOwnProperty("phoneNumber") &&
          selectedPerson.phoneNumber !== "") ? (
          <InputWithActions
            value={copySelectedPerson.phoneNumber}
            label='Numéro de téléphone'
            button={<MdModeEditOutline />}
            mainEditingButton={<IoCheckmarkOutline size={10} />}
            onClickMain={() => {
              setSelectedPerson(copySelectedPerson);
              setIsUpdatingPhone(false);
            }}
            secondEditingButton={<IoCloseOutline size={10} />}
            onClickSecond={() => {
              setCopySelectedPerson(selectedPerson);
              setIsUpdatingPhone(false);
            }}
            onChange={(value) => {
              setCopySelectedPerson({
                ...copySelectedPerson,
                phoneNumber: value
              });
            }}
            typing={!selectedPerson.hasOwnProperty("phoneNumber") && true}
          />
        ) : (
          <div>
            <div className='text-xs-bold color-neutral-700'>
              Numéro de téléphone
            </div>
            <Button
              styled='tertiary'
              label='Ajouter'
              onClick={() => {
                setIsUpdatingPhone(true);
              }}
              endIcon={<IoMdAdd />}
              size={"sm"}
            />
          </div>
        )}
      </div>
      <div className='columnn'>
        {isUpdatingAddress ||
        (selectedPerson.hasOwnProperty("address") &&
          selectedPerson.address !== "") ? (
          <>
            <InputWithActions
              value={copySelectedPerson.address}
              label='Adresse'
              button={<MdModeEditOutline />}
              mainEditingButton={<IoCheckmarkOutline size={10} />}
              onClickMain={() => {
                setSelectedPerson(copySelectedPerson);
                setIsUpdatingAddress(false);
              }}
              secondEditingButton={<IoCloseOutline size={10} />}
              onClickSecond={() => {
                setCopySelectedPerson(selectedPerson);
                setIsUpdatingAddress(false);
              }}
              onChange={(value) => {
                setCopySelectedPerson({
                  ...copySelectedPerson,
                  address: value
                });
              }}
              typing={!selectedPerson.hasOwnProperty("address") && true}
            />
          </>
        ) : (
          <div>
            <div className='text-xs-bold color-neutral-700'>Adresse</div>
            <Button
              styled='tertiary'
              label='Ajouter'
              onClick={() => {
                setIsUpdatingAddress(true);
              }}
              endIcon={<IoMdAdd />}
              size={"sm"}
            />
          </div>
        )}
      </div>
      <div>
        <div className='columnn'>
          {isUpdatingDate ||
          (selectedPerson.hasOwnProperty("birthDate") &&
            selectedPerson.birthDate !== "") ? (
            <InputWithActions
              value={copySelectedPerson.birthDate}
              label='Date de naissance'
              button={<MdModeEditOutline />}
              mainEditingButton={<IoCheckmarkOutline size={10} />}
              onClickMain={() => {
                setSelectedPerson(
                  JSON.parse(JSON.stringify(copySelectedPerson))
                );
                handleUpdatePerson(copySelectedPerson);
                setIsUpdatingDate(false);
              }}
              secondEditingButton={<IoCloseOutline size={10} />}
              onClickSecond={() => {
                setCopySelectedPerson(
                  JSON.parse(JSON.stringify(selectedPerson))
                );
                setIsUpdatingDate(false);
              }}
              onChange={(value) => {
                setCopySelectedPerson({
                  ...copySelectedPerson,
                  birthDate: `${
                    value.day + " " + value.month + " " + value.year
                  }`
                });
              }}
              typing={!selectedPerson.hasOwnProperty("birthDate") && true}
              isDate={true}
              years={[2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028]}
            />
          ) : (
            <div>
              <div className='text-xs-bold color-neutral-700'>
                Date de naissance
              </div>
              <Button
                styled='tertiary'
                label='Ajouter'
                onClick={() => {
                  setIsUpdatingDate(true);
                }}
                endIcon={<IoMdAdd />}
                size={"sm"}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const sideBarDisplayEditPersonContent = () => (
    <>
      <div className='wrapper'>
        <div>
          <span className='text-sm-semibold color-neutral-600'>
            Référence:{" "}
          </span>
          <span className='text-sm-regular color-neutral-1000'>
            {selectedPerson?.id?.slice(0, 8)}
          </span>
        </div>
        <div>
          <span className='name text-lg-medium color-neutral-800'>
            {selectedPerson.gender === "Madame" ? "Me " : "Mr "}
          </span>
          <span className='name text-lg-medium color-neutral-800'>
            {selectedPerson.firstName + " " + selectedPerson.lastName}
          </span>
        </div>
        <div className='email'>
          <span className='text-sm-semibold color-neutral-600 label'>
            E-mail:{" "}
          </span>
          <span className='mr-2'>
            <InputWithActions
              value={copySelectedPerson.email}
              button={<MdModeEditOutline size={16} />}
              mainEditingButton={<IoCheckmarkOutline size={10} />}
              onClickMain={() => {
                handleUpdatePerson(copySelectedPerson);
              }}
              secondEditingButton={<IoCloseOutline size={10} />}
              onClickSecond={() => {
                setKey("");
                setCopySelectedPerson(selectedPerson);
              }}
              onChange={(value) => {
                setKey("email");
                setCopySelectedPerson({ ...copySelectedPerson, email: value });
              }}
            />
          </span>
          <Button
            styled={"icon"}
            size={"sm"}
            icon={<Copy size={14} />}
            onClick={copyToClipBoard}
          />
          {mailCopied && (
            <span className='text-xs-regular color-primary-700 mt-1 ml-1'>
              Lien copié !
            </span>
          )}
        </div>
      </div>
      <div className='divider' />
      <div>
        <Accordeon
          title={"Informations"}
          children={accordionInfos()}
          action={
            <Button
              size={"sm"}
              styled='icon'
              icon={<BsThreeDotsVertical size={14} />}
            />
          }
          isChild={true}
        />
      </div>
      <div className='divider' />
      <Relations
        selectedElement={selectedPerson}
        setSelectedElement={setSelectedPerson}
        relations={relations}
        refreshData={() => getPerson(selectedPerson)}
      />
    </>
  );

  const handleCloseEditSidePanel = () =>{
    setOpenedSidePanelEditPerson(false)
    setCopySelectedPerson({})
  }

  return (
    <div className='personnes-container'>
      {openSidePanelAddPerson && (
        <SidePanel
          title='Ajouter une personne'
          onClose={() => setOpenedSidePanelAddPerson(false)}
          content={sideBarAddPersonContent()}
          primaryButton={
            <Button
              styled='primary'
              label='Ajouter'
              onClick={handleAddPerson}
              startIcon={<IoMdAdd size={22} />}
              blocked={isBlocked}
              loading={isSubmitting}
              size={"md"}
            />
          }
          secondaryButton={
            <Button
              styled='tertiary'
              label='Annuler'
              onClick={() => setOpenedSidePanelAddPerson(false)}
              size={"md"}
            />
          }
        />
      )}

      {openSidePanelEditPerson && (
        <SidePanel
          title={selectedPerson?.id?.slice(0, 8)}
          category='Personnes'
          onClose={() => handleCloseEditSidePanel()}
          content={sideBarDisplayEditPersonContent()}
          onClickPrimary={handleUpdatePerson}
        />
      )}
      {showSnackBar && (
        <Snackbar
          type={submitError ? "danger" : "success"}
          title={textSnackBar}
          size='small'
          autoClose={8000}
          closeCallBack={() => {
            setShowSnackBar(false);
          }}
        />
      )}
      <Header
        title={"Personnes"}
        subtitle={
          "Consultez ici la liste des personnes et créez des relations entre elles"
        }
        titleIcon={<GoStar color='#62AF98' />}
        primaryButton={
          dataList.length > 0 && (
            <Button
              styled='primary'
              label='Ajouter une personne'
              onClick={onClickMain}
              size={"md"}
              startIcon={<IoMdAdd size={22}/>}
            />
          )
        }
      />
      <div className='content-wrapper'>
        <Table
          onRowClick={(person) => {
            handleEditPerson(person);
          }}
          withSelect={false}
          pageSize={pageSize}
          setPageSize={(size) => {
            setPageSize(size);
          }}
          currentPage={page}
          setCurrentPage={(page) => {
            setPage(page);
          }}
          isLoading={isLoading}
          setSort={setSort}
          entriesCount={entriesCount}
          emptyPageTextButton='Ajouter une personne'
          columns={tableColumns}
          data={dataList}
          emptyPageTitle='Il n’y a aucune personne ajoutée dans la plateforme'
          emptyPageDesc='Cliquez sur “Ajouter une personne” pour commencer'
          onClickEmptyPage={onClickMain}
          pageSizes={[10, 20, 50]}
          selectedRowId={copySelectedPerson?.id}
        />
      </div>
    </div>
  );
};

export default Personnes;
